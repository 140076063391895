import { api } from './api'

type Products = any

const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProduct: builder.query<Products, string>({
      query: (id) => `crm/v1/products/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Products', id }],
    }),
  }),
})

export const { useGetProductQuery } = productsApi
