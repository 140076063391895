import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
  Button,
  ButtonGroup,
  FormFeedback,
} from 'reactstrap'
import { useSearchParams } from 'react-router-dom'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import * as Yup from 'yup'
import { useAddMainCompanyMutation } from 'src/services/companies'

type InputCompany = {
  [key: string]: {
    label: string
    number?: boolean
    boolean?: boolean
  }
}

const inputInitials: InputCompany = {
  full_name: { label: 'full_name' },
  short_name: { label: 'short_name' },
  ceo_name: { label: 'ceo_name' },
  inn: { label: 'inn', number: true },
  kpp: { label: 'kpp', number: true },
  ogrn: { label: 'ogrn', number: true },
  legal_address: { label: 'legal_address' },
  postal_address: { label: 'postal_address' },
  legal_email: { label: 'legal email' },
  // communication_email: { label: 'communication_email' },
  // registry_email: { label: 'registry_email' },
  customer_support_email: { label: 'customer_support_email' },
  phone: { label: 'phone' },
  // phone_prefix: { label: 'phone_prefix', number: true },
  // logo_url: { label: 'logo_url' },
  // is_owner: { label: 'is owner', boolean: true },
  // blocked: { label: 'blocked', boolean: true },
  website: { label: 'website' },
  bank_name: { label: 'bank_name' },
  bank_account: { label: 'bank_account' },
  bank_corr_account: { label: 'bank corr account' },
  bic: { label: 'bic' },
}

export const EditCompany = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [
    addCompany,
    {
      isSuccess: isSuccessAddCompany,
      error: createErrorAddCompany,
      isLoading: isLoadingAddCompany,
    },
  ] = useAddMainCompanyMutation()

  const title = searchParams.get('id') ? t('Edit company') : t('Add company')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: '',
      short_name: '',
      ceo_name: '',
      inn: 0,
      kpp: 0,
      ogrn: 0,
      legal_address: '',
      postal_address: '',
      legal_email: '',
      communication_email: '',
      registry_email: '',
      customer_support_email: '',
      phone: '',
      logo_url: '',
      phone_prefix: 0,
      website: '',
      bank_name: '',
      bank_account: '',
      bank_corr_account: '',
      bic: '',
      is_owner: false,
      blocked: null,
    },
    validationSchema: Yup.object().shape({
      full_name: Yup.string().required(t('Fill in the field')),
      short_name: Yup.string().required(t('Fill in the field')),
      ceo_name: Yup.string().required(t('Fill in the field')),
      inn: Yup.number().required(t('Fill in the field')),
      kpp: Yup.number().required(t('Fill in the field')),
      ogrn: Yup.number().required(t('Fill in the field')),
      legal_address: Yup.string().required(t('Fill in the field')),
      postal_address: Yup.string().required(t('Fill in the field')),
      legal_email: Yup.string().required(t('Fill in the field')),
      phone: Yup.string().required(t('Fill in the field')),
      bank_name: Yup.string().required(t('Fill in the field')),
      bank_account: Yup.string().required(t('Fill in the field')),
      bank_corr_account: Yup.string().required(t('Fill in the field')),
      bic: Yup.string().required(t('Fill in the field')),
    }),
    onSubmit: (values) => {
      console.log('VAL', values)
      addCompany(values)
    },
  })

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            createLink={undefined}
            backLink={'/companies'}
            editLink={undefined}
            entity={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              {Object.keys(inputInitials).map((fieldKey: string) => {
                return (
                  <Col className="col-3" key={`${fieldKey}`}>
                    <FormGroup className="mb-3">
                      <Label htmlFor={fieldKey}>
                        {t(inputInitials[fieldKey].label)}
                      </Label>
                      {inputInitials[fieldKey].number ? (
                        <Input
                          name={fieldKey}
                          type="number"
                          id={fieldKey}
                          className="form-control form-control-sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          /*@ts-ignore*/
                          value={formik.values[fieldKey] || ''}
                          disabled={isLoadingAddCompany}
                        />
                      ) : inputInitials[fieldKey].boolean ? (
                        <ButtonGroup className="w-100">
                          <Button
                            color="primary"
                            outline
                            /*@ts-ignore*/
                            active={formik.values[fieldKey] === true}
                            size="sm"
                            onClick={() => formik.setFieldValue(fieldKey, true)}
                            disabled={isLoadingAddCompany}
                          >
                            {t('yes')}
                          </Button>
                          <Button
                            color="primary"
                            outline
                            /*@ts-ignore*/
                            active={formik.values[fieldKey] === false}
                            size="sm"
                            onClick={() =>
                              formik.setFieldValue(fieldKey, false)
                            }
                            disabled={isLoadingAddCompany}
                          >
                            {t('no')}
                          </Button>
                        </ButtonGroup>
                      ) : (
                        <Input
                          name={fieldKey}
                          type="text"
                          id={fieldKey}
                          className="form-control form-control-sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          /*@ts-ignore*/
                          value={formik.values[fieldKey] || ''}
                          disabled={isLoadingAddCompany}
                        />
                      )}
                    </FormGroup>
                    {/*@ts-ignore*/}
                    {formik.touched[fieldKey] && formik.errors[fieldKey] ? (
                      <FormFeedback
                        style={{ display: 'block', marginBottom: '10px' }}
                        type="invalid"
                      >
                        <Alert color="warning">
                          {/*@ts-ignore*/}
                          <strong> {formik.errors[fieldKey]} </strong>
                        </Alert>
                      </FormFeedback>
                    ) : null}
                  </Col>
                )
              })}
            </Row>

            {createErrorAddCompany && (
              <Alert color="warning">
                <strong> {JSON.stringify(createErrorAddCompany)} </strong>
              </Alert>
            )}

            {isSuccessAddCompany && (
              <Alert color="success">
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
            <Button
              type="submit"
              color="primary"
              style={{ backgroundColor: '#405189' }}
              disabled={isLoadingAddCompany}
            >
              {t('Create')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
