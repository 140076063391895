import { useTranslation } from 'react-i18next'
import { Card, CardBody, Col, Row, ListGroup, ListGroupItem } from 'reactstrap'
import { useSearchParams } from 'react-router-dom'

import { ErrorInfo } from '../ErrorPage/ErrorInfo'
import LoadingPage from '../LoadingPage'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import { useGetMainCompanyViewQuery } from 'src/services/companies'

type InputCompany = {
  [key: string]: {
    label: string
    boolean?: boolean
  }
}

const inputInitials: InputCompany = {
  full_name: { label: 'full_name' },
  short_name: { label: 'short_name' },
  ceo_name: { label: 'ceo_name' },
  inn: { label: 'inn' },
  kpp: { label: 'kpp' },
  ogrn: { label: 'ogrn' },
  bank_name: { label: 'bank_name' },
  bank_account: { label: 'bank_account' },
  bank_corr_account: { label: 'bank corr account' },
  bic: { label: 'bic' },
  legal_address: { label: 'legal_address' },
  postal_address: { label: 'postal_address' },
  legal_email: { label: 'legal email' },
  communication_email: { label: 'communication_email' },
  registry_email: { label: 'registry_email' },
  customer_support_email: { label: 'customer_support_email' },
  phone: { label: 'phone' },
  phone_prefix: { label: 'phone prefix' },
  website: { label: 'website' },
  is_owner: { label: 'is owner', boolean: true },
  blocked: { label: 'blocked', boolean: true },
}

export const ViewCompany = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const {
    data: company,
    isError,
    error,
  } = useGetMainCompanyViewQuery(searchParams.get('id')!)

  const title = t('View company')

  if (isError) {
    return <ErrorInfo title={title} error={error} backLink={undefined} />
  }

  if (!company) {
    return <LoadingPage title={title} backLink={undefined} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            createLink={undefined}
            backLink={'/companies'}
            editLink={undefined}
            entity={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <ListGroup>
            <div className="mt-1 mb-1 text-center">
              <img src={company?.logo_url} alt="" />
            </div>
            {company &&
              Object.keys(inputInitials).map((fieldKey: string) => {
                return (
                  <ListGroupItem>
                    <Row>
                      <Col>{t(inputInitials[fieldKey].label)}</Col>

                      {inputInitials[fieldKey].boolean ? (
                        //@ts-ignore
                        <Col>{company[fieldKey] ? t('yes') : t('no')}</Col>
                      ) : (
                        //@ts-ignore
                        <Col>{company[fieldKey]}</Col>
                      )}
                    </Row>
                  </ListGroupItem>
                )
              })}
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  )
}
