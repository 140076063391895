import { api, omitBlankEntries } from './api'

type Client = {
  id: string
  last_name: string
  first_name: string
  middle_name: string
  gender: number
  birth_date: string
  occupation: string
  salary: number
}

export const clientsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<
      Client[],
      Partial<{
        client_id: string
        first_name: string
        middle_name: string
        last_name: string
        birth_date: string
        gender: number
        inn: string
        snils: string
        profile_id: string
        phone: string
        email: string
      }>
    >({
      query: (params) => ({
        url: 'crm/v1/clients',
        params: omitBlankEntries(params),
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Clients', id }) as const),
        { type: 'Clients' as const, id: 'LIST' },
      ],
    }),
  }),
})

export const { useGetClientsQuery } = clientsApi
