import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Input, Row, Col, Label } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import { getStrategy, getEntities } from 'src/store/actions'

import model from 'src/model'
import Client from 'src/client'

const mappingActionValue = (list) => {
  const value = []
  Object.keys(list).forEach((el) => {
    value.push(`Template_id: ${list[el].template_id}`)
  })
  if (!value.length) return '-'
  return value.join(', ')
}

const mappingListValue = (list) => list
const mappingArrValue = (list) => {
  if (list.length < 1) return '-'
  return list.join(', ')
}

const fieldsDescription = {
  loan_filters: {
    type: 'loan',
    dpd: { label: 'dpd', mappingValue: mappingListValue },
    collection_stage_ids: { label: 'Segments', mappingValue: mappingArrValue },
  },
  start_time: { type: 'action', label: 'start_time' },
  sms_actions: {
    type: 'action',
    label: 'sms',
    mappingValue: mappingActionValue,
  },
  email_actions: {
    type: 'action',
    label: 'email',
    mappingValue: mappingActionValue,
  },
  phonerobot_actions: {
    type: 'action',
    label: 'phonerobot',
    mappingValue: mappingActionValue,
  },
  tag_filters: {
    type: '',
    tag_ids: { label: 'tag_ids', mappingValue: mappingArrValue },
    stop_tag_ids: { label: 'stop_tag_ids', mappingValue: mappingArrValue },
  },
}

export const StrategyView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)

  const { strategy, strategyLoading, strategyError } = useSelector((state) => ({
    strategy: state.strategies.strategy,
    strategyLoading: state.strategies.strategyLoading,
    strategyError: state.strategies.strategyError,
  }))

  const { list: listSegments } = useSelector((state) => ({
    list: state.entities[Client.ENTITY_TYPE.SEGMENTS].list,
    listLoading: state.entities[Client.ENTITY_TYPE.SEGMENTS].listLoading,
    listError: state.entities[Client.ENTITY_TYPE.SEGMENTS].listError,
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    if (id) {
      dispatch(getStrategy(id))
      dispatch(getEntities(Client.ENTITY_TYPE.SEGMENTS))
    } else setParamError('No product id found')
  }, [window.location])

  useEffect(() => {
    if (!strategy) return
    setPageReady(true)
  }, [strategy])

  const idSegment = () => {
    const collectionArrId = strategy.loan_filters.collection_stage_ids
    const segmentArrNames = []
    for (let idCollection in collectionArrId) {
      for (let idSegment in listSegments) {
        if (collectionArrId[idCollection] === listSegments[idSegment].id)
          segmentArrNames.push(listSegments[idSegment].name)
      }
    }
    if (segmentArrNames.length < 1) return '-'
    else return segmentArrNames.join(', ')
  }

  console.log('stratagy =>', strategy)
  console.log('segments', listSegments)

  const title = `${t('strategies')}: ${t('View')}`
  const metaBar = (
    <MetaBar
      backLink={'/strategy'}
      editLink={strategy ? `/strategy-edit?id=${strategy.id}` : null}
      entity={strategy}
    />
  )

  if (paramError || strategyError) {
    return (
      <ErrorPage
        backLink={'/strategy'}
        title={title}
        error={[paramError, strategyError]}
      />
    )
  }

  if (!pageReady || strategyLoading) {
    return <LoadingPage backLink={'/strategy'} title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            {metaBar}
            <Row className="mt-3">
              <div>
                <b>{t('strategy_name')}:</b>
              </div>
              <div>
                {t(model.fieldValueToText('name', strategy, fieldsDescription))}
              </div>
            </Row>
            <Row className="mt-3">
              <div>
                <b>{t('status')}:</b>
              </div>
              <div>
                {t(
                  model.fieldValueToText(
                    'is_active',
                    strategy,
                    fieldsDescription,
                  ),
                )}
              </div>
            </Row>
            <Row className="mt-3">
              <div>
                <b>{t('Other')}:</b>
              </div>
              {Object.keys(fieldsDescription)
                .filter((key) => fieldsDescription[key].type === 'loan')
                .map((fieldName) => {
                  return (
                    <Col className={'col-12 mt-2 mb-2'} key={`${fieldName}`}>
                      <Label htmlFor={fieldName}>
                        {t(fieldsDescription[fieldName].dpd.label || fieldName)}
                      </Label>
                      <Input
                        type="text"
                        className="form-control form-control-sm"
                        value={model.fieldValueToText(
                          'dpd',
                          strategy[fieldName],
                          fieldsDescription[fieldName],
                        )}
                        disabled
                      />
                      <Label className="mt-3" htmlFor={fieldName}>
                        {t(
                          fieldsDescription[fieldName].collection_stage_ids
                            .label || fieldName,
                        ).toLowerCase()}
                      </Label>
                      <Input
                        type="text"
                        className="form-control form-control-sm"
                        value={idSegment()}
                        disabled
                      />
                    </Col>
                  )
                })}
              {Object.keys(fieldsDescription)
                .filter((key) => fieldsDescription[key].type === 'action')
                .map((fieldName) => {
                  return (
                    <Col className={'col-12 mt-2 mb-2'} key={`${fieldName}`}>
                      <Label htmlFor={fieldName}>
                        {t(fieldsDescription[fieldName].label || fieldName)}
                      </Label>
                      <Input
                        type="text"
                        className="form-control form-control-sm"
                        value={model.fieldValueToText(
                          fieldName,
                          strategy,
                          fieldsDescription,
                        )}
                        disabled
                      />
                    </Col>
                  )
                })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
