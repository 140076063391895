export interface PersistentWebSocketProps {
  url: string
  reconnectInterval?: number
  maxReconnectInterval?: number
  reconnectDecay?: number
  maxReconnectAttempts?: number
  enableReconnect?: boolean
}

/*
 * Wrapper around WebSocket that persists event listeners and re-attaches them when new connection established
 */
export class PersistentWebSocket {
  private messageQueue: Array<
    string | ArrayBufferLike | Blob | ArrayBufferView
  > = []

  private url: string
  private websocket: WebSocket | null
  private eventListeners: {
    open?: Array<(this: WebSocket, ev: WebSocketEventMap['open']) => any>
    close?: Array<(this: WebSocket, ev: WebSocketEventMap['close']) => any>
    message?: Array<(this: WebSocket, ev: WebSocketEventMap['message']) => any>
    error?: Array<(this: WebSocket, ev: WebSocketEventMap['error']) => any>
  }
  /** The number of milliseconds to delay before attempting to reconnect. */
  public reconnectInterval: number
  private reconnectIntervalOriginal: number
  /** The maximum number of milliseconds to delay a reconnection attempt. */
  public maxReconnectInterval: number
  /** The rate of increase of the reconnect delay. Allows reconnect attempts to back off when problems persist. */
  public reconnectDecay: number

  /** The maximum number of reconnection attempts to make. Unlimited if null. */
  public maxReconnectAttempts: number
  private maxReconnectAttemptsOriginal: number
  private enableReconnect: boolean

  constructor({
    url,
    reconnectInterval = 1000,
    // one minute
    maxReconnectInterval = 60_000,
    // 1->10->100 seconds
    reconnectDecay = 10,
    maxReconnectAttempts = 6,
    enableReconnect = false,
  }: PersistentWebSocketProps) {
    this.url = url
    this.websocket = null
    this.eventListeners = {}
    this.reconnectInterval = reconnectInterval
    this.reconnectIntervalOriginal = reconnectInterval
    this.reconnectDecay = reconnectDecay
    this.maxReconnectInterval = maxReconnectInterval
    this.maxReconnectAttempts = maxReconnectAttempts
    this.maxReconnectAttemptsOriginal = maxReconnectAttempts
    this.enableReconnect = enableReconnect

    // Create the WebSocket connection
    this.connect()
  }

  private connect(): void {
    this.websocket = new WebSocket(this.url)

    // Attach event listeners
    this.websocket.addEventListener('open', this.handleOpen.bind(this))
    this.websocket.addEventListener('close', this.handleClose.bind(this))
    this.websocket.addEventListener('error', this.handleError.bind(this))
  }

  private handleOpen(_event: Event): void {
    // reset interval and attempts if connection was successful
    this.reconnectInterval = this.reconnectIntervalOriginal
    this.maxReconnectAttempts = this.maxReconnectAttemptsOriginal

    // Reattach event listeners if the connection is reopened
    this.reattachEventListeners()

    // send messages that were not sent previously
    while (this.messageQueue.length > 0) {
      this.websocket!.send(this.messageQueue.shift()!)
    }
  }

  private async handleClose(_event: CloseEvent) {
    if (this.enableReconnect) return
    // if no attempts left do stop reconnecting
    if (this.maxReconnectAttempts <= 0) return

    // wait for either reconnectInterval or if it exceeds max - choose maxReconnectInterval instead
    await delay(Math.min(this.reconnectInterval, this.maxReconnectInterval))
    // make reconnectInterval longer by multiplying reconnectDecay
    this.reconnectInterval *= this.reconnectDecay
    // decrease maxReconnectAttempts
    this.maxReconnectAttempts--

    // Automatically reconnect
    this.connect()
  }

  private handleError(event: Event): void {
    console.error('WebSocket error:', event)
  }

  public addEventListener<K extends keyof WebSocketEventMap>(
    eventName: K,
    listener: (this: WebSocket, ev: WebSocketEventMap[K]) => any,
  ): void {
    this.eventListeners[eventName] ??= []
    // @ts-ignore
    this.eventListeners[eventName]!.push(listener)

    // NOTE: warn user if attaching to non-existent connection?
    // If WebSocket is already open, attach the event listener
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.addEventListener(eventName, listener)
    }
  }

  public removeEventListener<K extends keyof WebSocketEventMap>(
    eventName: K,
    listener: (this: WebSocket, ev: WebSocketEventMap[K]) => any,
  ): void {
    if (this.eventListeners[eventName]) {
      const index = this.eventListeners[eventName]!.findIndex(
        (fn) => fn === listener,
      )
      if (index !== -1) {
        this.eventListeners[eventName]!.splice(index, 1)
        // If WebSocket is open, detach the event listener
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
          this.websocket.removeEventListener(eventName, listener)
        }
      }
    }
  }

  private reattachEventListeners(): void {
    Object.entries(this.eventListeners).forEach(
      ([eventName, callbacks]) =>
        callbacks?.forEach(
          (callback) =>
            this.websocket?.addEventListener(
              eventName as keyof WebSocketEventMap,
              // @ts-ignore
              callback,
            ),
        ),
    )
  }

  public close(): void {
    this.websocket?.close()
  }

  public send(data: string | ArrayBufferLike | Blob | ArrayBufferView): void {
    this.messageQueue.push(data)
    if (this.websocket?.readyState === 1)
      this.websocket.send(this.messageQueue.pop()!)
  }
}

const delay = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))
