import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import LimitNotice from 'src/Components/Common/LimitNotice'
import List from 'src/Components/Common/EntitiesView/List'
import ErrorPage from '../ErrorPage'

import model from 'src/model'

import { useTranslation } from 'react-i18next'

import { getLoans } from 'src/store/actions'

const debounceTimers = {}
const DEBOUNCE_TIMEOUT = 2000

export const Loans = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [strFilter, setStrFilter] = useState('')
  const [fieldsFilter, setFieldsFilter] = useState({})
  const [filter, setFilter] = useState([])
  const [sortOrder, setSortOrder] = useState({
    issue_date: 'desc',
    repayment_date: 'desc',
    last_login: 'desc',
    amount_debt: 'desc',
    term: 'desc',
    dpd: 'desc',
  })

  const { list, listError } = useSelector((state) => ({
    list: state.loans.list,
    listLoading: state.loans.listLoading,
    listError: state.loans.listError,
  }))

  const sortByValue = (keyName) => {
    const sortedFilter = [...filter]
    const newSortOrder = {
      ...sortOrder,
      [keyName]: sortOrder[keyName] === 'asc' ? 'desc' : 'asc',
    }
    sortedFilter.sort((a, b) => {
      if (newSortOrder[keyName] === 'asc') {
        return a[keyName] - b[keyName]
      } else {
        return b[keyName] - a[keyName]
      }
    })
    setFilter(sortedFilter)
    setSortOrder(newSortOrder)
  }

  const sortByDate = (keyName) => {
    const sortedFilter = [...filter]
    const newSortOrder = {
      ...sortOrder,
      [keyName]: sortOrder[keyName] === 'asc' ? 'desc' : 'asc',
    }
    sortedFilter.sort((a, b) => {
      if (newSortOrder[keyName] === 'asc') {
        return new Date(a[keyName]) - new Date(b[keyName])
      } else {
        return new Date(b[keyName]) - new Date(a[keyName])
      }
    })
    setFilter(sortedFilter)
    setSortOrder(newSortOrder)
  }

  const fieldsDescription = {
    id: {
      label: ' ',
      mappingValue: () => <i className="ri-eye-line fs-15" />,
      isLink: true,
      linkFieldName: 'id_link',
    },
    collector_fio: {
      label: 'Collector full name',
    },
    status: {},
    issue_date: { isDate: true, onClick: () => sortByDate('issue_date') },
    repayment_date: {
      isDate: true,
      onClick: () => sortByDate('repayment_date'),
    },
    last_login: { isDate: true, onClick: () => sortByDate('last_login') },
    amount_debt: {
      isAmount: true,
      rightAligned: true,
      onClick: () => sortByValue('amount_debt'),
    },
    term: { rightAligned: true, onClick: () => sortByValue('term') },
    dpd: { rightAligned: true, onClick: () => sortByValue('dpd') },
    extension_available: { rightAligned: true },
    extension_status: { rightAligned: true },
    timezone: { rightAligned: true },
    application_id: {
      label: 'application',
      mappingValue: () => <i className="ri-file-edit-line fs-15" />,
      isLink: true,
      linkFieldName: 'application_link',
    },
    profile_id: {
      label: 'profile',
      mappingValue: () => <i className="ri-account-circle-line fs-15" />,
      isLink: true,
      linkFieldName: 'profile_link',
    },
  }

  useEffect(() => {
    dispatch(getLoans())
  }, [dispatch])

  useEffect(() => {
    if (list) {
      setFilter(list)
    }
  }, [list])

  useEffect(() => {
    if (!strFilter) return

    clearTimeout(debounceTimers[`filtered-entities-${strFilter}`])
    debounceTimers[`filtered-entities-${strFilter}`] = setTimeout(() => {
      console.log(`request list with ${strFilter}`)
      dispatch(getLoans(null, strFilter))
    }, DEBOUNCE_TIMEOUT)

    return () => {
      if (debounceTimers[`filtered-entities-${strFilter}`]) {
        clearTimeout(debounceTimers[`filtered-entities-${strFilter}`])
      }
    }
  }, [strFilter])

  useEffect(() => {
    if (Object.keys(fieldsFilter).length === 0) return

    const fieldsFilterJson = JSON.stringify(fieldsFilter)
    clearTimeout(debounceTimers[`filtered-entities-${fieldsFilterJson}`])
    debounceTimers[`filtered-entities-${fieldsFilterJson}`] = setTimeout(() => {
      console.log(`request list with ${fieldsFilterJson}`)
      if (fieldsFilter.gender)
        fieldsFilter.gender = model.getGenderCode(fieldsFilter.gender)
      dispatch(getLoans(null, fieldsFilter))
    }, DEBOUNCE_TIMEOUT)

    return () => {
      if (debounceTimers[`filtered-entities-${fieldsFilterJson}`]) {
        clearTimeout(debounceTimers[`filtered-entities-${fieldsFilterJson}`])
      }
    }
  }, [fieldsFilter])

  console.log('loans =>', list)
  const title = t('Loans')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  /*if (listLoading) {
    return <LoadingPage title={title} />;
  }*/

  const bread = <BreadCrumb title={title} />
  return (
    <>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            <div className="live-preview">
              <div className="table-responsive table-card">
                <div className="gridjs-head">
                  <div className="gridjs-search">
                    <input
                      type="search"
                      className="gridjs-input gridjs-search-input"
                      id="iconInput"
                      placeholder="Search..."
                      onChange={(event) => {
                        const filter = event.target.value
                        setStrFilter(filter)
                      }}
                    />
                  </div>
                </div>

                <FieldsFilter
                  fields={{
                    loan_id: {},
                    collector_fio: {
                      label: 'Collector full name',
                    },
                    loan_status: { options: ['Opened', 'Closed'] },
                    application_id: {},
                    client_id: {},
                    profile_id: {},
                    group_delimiter: {},
                    last_name: {},
                    first_name: {},
                    middle_name: {},
                    group_delimiter_2: {},
                    phone: {},
                    email: {},
                    birth_date: { date: true },
                    gender: { options: ['male', 'female'] },
                    loan_short_name: {},
                  }}
                  applyFilter={(values) => setFieldsFilter(values)}
                  resetFilter={() => setFieldsFilter({})}
                />
                <LimitNotice limit={model.LIST_LIMIT} />
                <List
                  style={{ width: 'fit-content' }}
                  list={filter.map((item) => ({
                    ...item,
                    id_link: `/loan-view?id=${item.id}`,
                    profile_link: `/profile-view?id=${item.profile_id}`,
                    application_link: `/application-view?id=${item.request_id}`,
                    extension_available: item.ext_details?.extension_available,
                    extension_status: item.extensions?.status,
                  }))}
                  fieldsDescription={fieldsDescription}
                  className="table-sm align-middle table-nowrap mb-0"
                  hover
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
