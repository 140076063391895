import Table4Columns from './Table4Columns'
import { useGetProductQuery } from 'src/services/products'

const columnsDescription = [
  ['product_title'],
  ['product_id'],
  ['product_code'],
  ['product_name'],
  ['product_priority'],
  ['product_status'],
  ['manual_verification'],
  ['autosign_application'],
  ['time_to_sign'],
  ['min_loan_term'],
  ['max_loan_term'],
  ['min_loan_amount'],
  ['max_loan_amount'],
  ['new_line'],
  ['product_interest_rate'],
  ['extension_interest_rate'],
  ['product_interest_rate_overdue'],
  ['late_loan_interest_rate'],
  ['product_late_fee'],
  ['product_issuance_fee'],
  ['product_repayment_fee'],
  ['coef_max_overpayment'],
  ['new_line'],
  ['loan_holidays_total'],
  ['max_loan_holidays_term'],
  ['extension_title'],
  ['extension_enabled'],
  ['ext_with_penalty'],
  ['max_extensions_number'],
  ['ext_overdue_allowed_days'],
  ['ext_from_sign_day'],
  ['max_ext_days'],
  ['min_ext_days'],
  ['max_loan_days'],
  ['ext_fee_percent'],
  ['ext_fee_amount'],
  ['ext_all_interest_amount'],
]

const fieldsDescription = {
  product_id: { fieldName: 'id', isLink: true, linkFieldName: 'product_link' },
  product_title: { isTitle: true, text: 'product_settings' },
  extension_title: { isTitle: true, text: 'extensions_settings' },
  new_line: { isEmpty: true, newLine: true },
  ext_fee_amount: { isAmount: true },
  ext_fee_percent: { isAmount: true },
  coef_max_overpayment: { isAmount: true },
  product_interest_rate: { fieldName: 'interest_rate', isAmount: true },
  product_issuance_fee: { fieldName: 'issuance_fee', isAmount: true },
  product_late_fee: { fieldName: 'late_fee', isAmount: true },
  product_repayment_fee: { fieldName: 'repayment_fee', isAmount: true },
  product_interest_rate_overdue: {
    fieldName: 'delinquency_interest_rate',
    isAmount: true,
  },
  used_extensions_the_term: { fieldName: 'prolongation_count' },
  max_loan_amount: { isAmount: true },
  min_loan_amount: { isAmount: true },
  max_overpayment_amount: { isAmount: true },
}

const EntityContent = ({ visible, entityId }) => {
  const { data: product_original } = useGetProductQuery(entityId, {
    skip: entityId == null || !visible,
  })

  if (product_original && product_original.extension_setting)
    delete product_original.extension_setting['id']

  return (
    <>
      {product_original && (
        <Table4Columns
          columnsDescription={columnsDescription}
          fieldsDescription={fieldsDescription}
          className="table-sm align-middle mb-0"
          style={{ width: 'max-content' }}
          entity={{
            ...product_original,
            product_link: `/product-view?id=${product_original.id}`,
          }}
        />
      )}
    </>
  )
}

export default EntityContent
