import { useEffect, useState } from 'react'
import Client from '../../../client'
import List from './List'
import Loader from '../Loader'

const EntityContent = ({ entityId, style, visible }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState([])
  const [error, setError] = useState('')

  const fetchLoans = async () => {
    setIsLoading(true)
    try {
      const res = await Client.getLoansBki(entityId)
      console.log('getLoansBki', res)
      const updatedItems = Object.values(res)
        .flat()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

      setItems(updatedItems)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (visible) {
      fetchLoans()
    }
  }, [visible])

  const fieldsDescription = {
    company: {},
    created_at: { isDateTime: true },
    file_name: {},
    event_name: {},
    loan_uuid_cbr: {},
  }

  return (
    <div>
      {error && <div className="mt-2 text-danger">{error.toString()}</div>}
      {isLoading && <Loader />}
      {!isLoading && (
        <List
          list={items}
          style={style}
          fieldsDescription={fieldsDescription}
        />
      )}
    </div>
  )
}

export default EntityContent
