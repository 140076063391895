import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { useGetCommunicationsQuery } from 'src/services/communications'
import List from './List'
import { ENTITY_TYPE, LIST_LIMIT } from 'src/client'
import LimitNotice from '../LimitNotice'
import model from 'src/model'
import Loader from '../Loader'
import { CommunicationView } from 'src/pages/Admin/Communication/View'
import { UseQueryHookResultData } from 'src/services/api'
import ChatUI from './Chat/Chat'

type Props = {
  entityType: keyof typeof ENTITY_TYPE
  entityId: string
  icon: unknown | null
  title: string
  visible: boolean
}

const EntityContent: FC<Props> = ({ entityId, visible }) => {
  const { t } = useTranslation()

  const [active, setActive] =
    useState<
      UseQueryHookResultData<typeof useGetCommunicationsQuery>[0]['type']
    >('sms')

  const {
    data: communications,
    isFetching,
    isSuccess,
  } = useGetCommunicationsQuery(
    {
      profile_id: entityId,
      type: active,
      limit: LIST_LIMIT,
      offset: 0,
    },
    {
      skip: entityId == null || !visible,
    },
  )

  const [communication, setCommunication] =
    useState<UseQueryHookResultData<typeof useGetCommunicationsQuery>[0]>()

  const [showModal, setShowModal] = useState(false)

  const handleCommunicationClick = useCallback(
    (listItem: UseQueryHookResultData<typeof useGetCommunicationsQuery>[0]) => {
      setCommunication(listItem)
      setShowModal(true)
    },
    [],
  )

  const fieldsDescription = useMemo(
    () => ({
      id: {
        label: ' ',
        mappingValue: () => <i className="ri-eye-line fs-15" />,
        isButton: true,
        onClick: handleCommunicationClick,
      },
      status: {
        mappingValue: (value: string) => t(mapStatusToName(value)),
        mappingClassName: (value: string) => mapStatusToClassName(value),
      },
      profile_id: {},
      created_at: { isDateTime: true },
      updated_at: { isDateTime: true },
    }),
    [handleCommunicationClick, t],
  )

  const toggle = () => setShowModal(false)

  return (
    <>
      <Nav tabs className="nav-tabs nav-border-top mb-3">
        <NavItem>
          <NavLink
            active={active === 'sms'}
            onClick={() => setActive('sms')}
            href="#"
          >
            {t('SMS')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={active === 'email-outgoing'}
            onClick={() => setActive('email-outgoing')}
            href="#"
          >
            {t('Email outgoing')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={active === 'call-human'}
            onClick={() => setActive('call-human')}
            href="#"
          >
            {t('Human call')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={active === 'call-robot'}
            onClick={() => setActive('call-robot')}
            href="#"
          >
            {t('Robot call')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={active === 'email-incoming'}
            onClick={() => setActive('email-incoming')}
            href="#"
          >
            {t('Email incoming')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={active === 'message-chat'}
            onClick={() => setActive('message-chat')}
            href="#"
          >
            {t('Chat')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={active === 'message-lk'}
            onClick={() => setActive('message-lk')}
            href="#"
          >
            {t('Landing page messages')}
          </NavLink>
        </NavItem>
      </Nav>

      {isFetching && <Loader />}

      {active === 'message-chat' ? (
        <ChatUI profile_id={entityId} />
      ) : (
        isSuccess &&
        communications!.length > 0 && (
          <>
            <LimitNotice limit={model.LIST_LIMIT} />
            <List
              list={communications}
              fieldsDescription={fieldsDescription}
              style={undefined}
              className={undefined}
              hover={undefined}
              actions={undefined}
            />
          </>
        )
      )}

      {isSuccess &&
        communications!.length === 0 &&
        active !== 'message-chat' && (
          <div style={{ marginBottom: '10px' }}>
            <dl className="row mb-0">
              <small>{t('No data')}</small>
            </dl>
          </div>
        )}

      <Modal isOpen={showModal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{t('Communication')}</ModalHeader>

        <ModalBody>
          <CommunicationView communication={communication} />
        </ModalBody>
      </Modal>
    </>
  )
}

export const mapStatusToName = (status: string) => {
  switch (status) {
    case '200':
      return 'Success'
    case '400':
      return 'Error'
    case '422':
      return 'Error'
    default:
      return status
  }
}

export const mapStatusToClassName = (status: string) => {
  switch (status) {
    case '200':
      return 'text-success'
    case '400':
      return 'text-warning'
    case '422':
      return 'text-warning'
    default:
      break
  }
}

export default EntityContent
