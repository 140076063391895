import Table4Columns from './Table4Columns'
import { omit } from 'src/utils'
import { useGetProductQuery } from 'src/services/products'

const columnsDescription = [
  ['product_title'],
  ['product_id'],
  ['product_name'],
  ['product_code'],
  ['product_status'],
  ['min_loan_term'],
  ['max_loan_term'],
  ['min_loan_amount'],
  ['max_loan_amount'],

  [/*'title_product_loan'*/ null, 'title_product_original'],

  ['loan_interest_rate', 'product_interest_rate'],
  ['loan_interest_rate_overdue', 'product_interest_rate_overdue'],
  ['loan_late_fee', 'product_late_fee'],
  ['loan_issuance_fee', 'product_issuance_fee'],
  ['loan_repayment_fee', 'product_repayment_fee'],

  ['new_line'],

  ['extension_interest_rate'],
  ['late_loan_interest_rate'],
  ['coef_max_overpayment'],
  ['new_line'],
  ['max_loan_holidays_term'],
  ['loan_holidays_total'],

  ['extension_title'],
  ['extension_enabled'],
  ['ext_with_penalty'],
  ['max_extensions_number'],
  ['ext_overdue_allowed_days'],
  ['ext_from_sign_day'],
  ['max_ext_days'],
  ['min_ext_days'],
  ['max_loan_days'],
  ['ext_fee_percent'],
  ['ext_fee_amount'],
  ['ext_all_interest_amount'],
]

const fieldsDescription = {
  product_id: { fieldName: 'id', isLink: true, linkFieldName: 'product_link' },
  product_title: { isTitle: true, text: 'product_settings' },
  extension_title: { isTitle: true, text: 'extensions_settings' },
  new_line: { isEmpty: true, newLine: true },
  ext_fee_amount: { isAmount: true },
  ext_fee_percent: { isAmount: true },
  coef_max_overpayment: { isAmount: true },

  title_product_loan: { isTitle: true, text: 'loan', titleLevel: 2 },
  title_product_original: {
    isTitle: true,
    text: 'product_original',
    titleLevel: 2,
  },

  product_interest_rate: { label: 'interest_rate', isAmount: true },
  product_issuance_fee: { label: 'issuance_fee', isAmount: true },
  product_late_fee: { label: 'late_fee', isAmount: true },
  product_repayment_fee: { label: 'repayment_fee', isAmount: true },
  product_interest_rate_overdue: {
    label: 'delinquency_interest_rate',
    isAmount: true,
  },

  loan_interest_rate: { fieldName: 'interest_rate', isAmount: true },
  loan_issuance_fee: { fieldName: 'issuance_fee', isAmount: true },
  loan_late_fee: { fieldName: 'late_fee', isAmount: true },
  loan_repayment_fee: { fieldName: 'repayment_fee', isAmount: true },
  loan_interest_rate_overdue: {
    fieldName: 'delinquency_interest_rate',
    isAmount: true,
  },

  used_extensions_the_term: { fieldName: 'prolongation_count' },
  max_loan_amount: { isAmount: true },
  min_loan_amount: { isAmount: true },
  max_overpayment_amount: { isAmount: true },
}

const EntityContent = ({ visible, loan }) => {
  const { data: product_original } = useGetProductQuery(loan.product_copy?.id, {
    skip: loan.product_copy?.id == null || !visible,
  })

  const entity = {
    product_loan: loan.product_copy,
    product_original,
  }

  const productOriginalFields = entity.product_original
    ? {
        product_interest_rate: entity.product_original.interest_rate,
        product_issuance_fee: entity.product_original.issuance_fee,
        product_late_fee: entity.product_original.late_fee,
        product_repayment_fee: entity.product_original.repayment_fee,
        product_interest_rate_overdue:
          entity.product_original.delinquency_interest_rate,
      }
    : {}

  let { extension_setting, ...product_loan } = entity.product_loan

  return (
    <>
      {product_original && (
        <Table4Columns
          columnsDescription={columnsDescription}
          fieldsDescription={fieldsDescription}
          className="table-sm align-middle mb-0"
          style={{ width: 'max-content' }}
          entity={{
            ...productOriginalFields,
            ...product_loan,
            ...omit(extension_setting, ['id']),
            product_link: `/product-view?id=${entity.product_loan.id}`,
          }}
        />
      )}
    </>
  )
}

export default EntityContent
