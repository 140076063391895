import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../../ErrorPage'
import LoadingPage from '../../LoadingPage'
import { Link } from 'react-router-dom'
import {
  useDeleteTextTemplateMutation,
  useGetTextTemplatesQuery,
} from 'src/services/templatesClaim'
import model from 'src/model'

const headers = {
  id: {},
  name: { label: 'name' },
  text_template: { label: 'text_templates' },
  created_at: { label: 'created_at' },
  updated_at: { label: 'updated_at' },
}

const actionsEnabled = true
const enableDelete = true

export const TemplatesClaim = () => {
  const { t } = useTranslation()

  const {
    data: templates,
    isError,
    error,
    refetch,
  } = useGetTextTemplatesQuery({})

  const [deleteTemplate, { isSuccess: isDeleteSuccess }] =
    useDeleteTextTemplateMutation()

  useEffect(() => {
    refetch()
  }, [isDeleteSuccess, refetch])

  const title = t('Templates claim')

  if (isError) {
    return <ErrorPage title={title} error={error} backLink={undefined} />
  }

  if (!templates) {
    return <LoadingPage title={title} backLink={undefined} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            createLink={'/claim-templates-create'}
            backLink={undefined}
            editLink={undefined}
            entity={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <div className="table-responsive table-card mt-4">
            <Table hover className="table-sm align-middle table-nowrap mb-0">
              <thead>
                <tr>
                  <th />
                  {Object.keys(headers).map((header) => (
                    <th className="ps-3" key={header} scope="col">
                      {t(header)}
                    </th>
                  ))}
                  {actionsEnabled && <th scope="col">{t('Actions')}</th>}
                </tr>
              </thead>
              <tbody>
                {templates?.map((entity) => {
                  return (
                    <tr key={entity.id}>
                      <td style={{ width: '1%' }}>
                        <Link
                          to={`/claim-templates-view/?id=${entity.id}`}
                          className="fw-medium"
                        >
                          <i className="ri-eye-line fs-15" />
                        </Link>
                      </td>
                      {Object.keys(headers).map((fieldName) => {
                        if (fieldName === 'id')
                          return (
                            <td className="ps-3" key={fieldName}>
                              {entity.id}
                            </td>
                          )
                        return (
                          <td
                            className="ps-3"
                            key={fieldName}
                            style={{ whiteSpace: 'normal' }}
                          >
                            {model.fieldValueToText(fieldName, entity, headers)}
                          </td>
                        )
                      })}
                      {actionsEnabled && (
                        <td>
                          <div className="hstack gap-3 flex-wrap">
                            <Link
                              to={`/claim-templates-edit/?id=${entity.id}`}
                              className="link-success fs-15"
                            >
                              <i className="ri-edit-2-line"></i>
                            </Link>
                            {enableDelete && (
                              <Link
                                to="#"
                                onClick={() => deleteTemplate(entity.id)}
                                className="link-danger fs-15"
                              >
                                <i className="ri-delete-bin-line"></i>
                              </Link>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
