import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

//import Components
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import RightSidebar from '../Components/Common/RightSidebar'
import TaskModal from 'src/Routes/TaskModal'

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
} from '../store/actions'

//redux
import { useSelector, useDispatch } from 'react-redux'

export const Layout = () => {
  const [headerClass, setHeaderClass] = useState('')
  const dispatch = useDispatch()
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
  } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    leftSidebarType: state.Layout.leftSidebarType,
    layoutModeType: state.Layout.layoutModeType,
    layoutWidthType: state.Layout.layoutWidthType,
    layoutPositionType: state.Layout.layoutPositionType,
    topbarThemeType: state.Layout.topbarThemeType,
    leftsidbarSizeType: state.Layout.leftsidbarSizeType,
    leftSidebarViewType: state.Layout.leftSidebarViewType,
    leftSidebarImageType: state.Layout.leftSidebarImageType,
  }))

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType
    ) {
      dispatch(changeLeftsidebarViewType(leftSidebarViewType))
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType))
      dispatch(changeSidebarTheme(leftSidebarType))
      dispatch(changeLayoutMode(layoutModeType))
      dispatch(changeLayoutWidth(layoutWidthType))
      dispatch(changeLayoutPosition(layoutPositionType))
      dispatch(changeTopbarTheme(topbarThemeType))
      dispatch(changeLayout(layoutType))
      dispatch(changeSidebarImageType(leftSidebarImageType))
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    dispatch,
  ])
  /*
    call dark/light mode
    */
  useEffect(() => {
    if (!localStorage.getItem('theme'))
      localStorage.setItem('theme', layoutModeType)
    if (localStorage.getItem('theme') === 'dark') {
      if (changeLayoutMode) {
        dispatch(changeLayoutMode(localStorage.getItem('theme')))
      }
    }
  }, [dispatch, layoutModeType])

  const onChangeLayoutMode = (value) => {
    localStorage.setItem('theme', value)
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value))
    }
  }

  // class add remove in header
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true)
  })
  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow')
    } else {
      setHeaderClass('')
    }
  }

  return (
    <>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={localStorage.getItem('theme')}
          onChangeLayoutMode={onChangeLayoutMode}
        />
        <Sidebar layoutType={layoutType} />
        <div className="main-content">
          <Outlet />
          <Footer />
        </div>
      </div>
      <RightSidebar />
      <TaskModal task={null} />
    </>
  )
}
