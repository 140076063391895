import { FC } from 'react'

import List from './List'
import { useGetChargebackQuery } from 'src/services/payments'

type props = {
  entityId: string
}

export const Chargeback: FC<props> = ({ entityId }) => {
  const { data: chargeback } = useGetChargebackQuery(entityId, {
    skip: !entityId,
  })

  return (
    <>
      {chargeback && (
        <List
          list={chargeback.map((item) => ({
            ...item,
            id_link: `/payment-view?id=${item.funds_transaction_id}`,
            fio_user: `${item.surname} ${item.name} ${item.midname}`,
          }))}
          className={undefined}
          fieldsDescription={{
            funds_transaction_id: {
              isLink: true,
              linkFieldName: 'id_link',
            },
            fio_user: { label: 'Full name of the employee' },
            username: {},
            chargeback_date: { isDate: true },
            created_at: { isDate: true },
          }}
          style={undefined}
          hover
          actions={undefined}
        />
      )}
    </>
  )
}
