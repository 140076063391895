import List from './List'

import { useGetLoanOperationsQuery } from 'src/services/loans'

const fieldsDescription = {
  id: { isShortId: false },
  created_at: { isDateTime: true },
  operation_type: {},
  direction: {},
  amount: { isAmount: true, rightAligned: true },
}

const EntityContent = ({ entityId, style, visible }) => {
  const { data: operations } = useGetLoanOperationsQuery(entityId, {
    skip: !visible,
  })
  return (
    <>
      {operations?.length && (
        <List
          list={operations}
          fieldsDescription={fieldsDescription}
          style={style}
        />
      )}
    </>
  )
}

export default EntityContent
