import { api, omitBlankEntries } from './api'

type UserItem = {
  id: string
  name: string
}

export const { useGetUsersQuery } = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<UserItem[], Partial<UserItem>>({
      query: (params) => ({
        url: '/crm/v1/keycloak/users',
        params: omitBlankEntries(params),
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'UserItem', id }) as const),
        { type: 'UserItem' as const, id: 'LIST' },
      ],
    }),
  }),
})
