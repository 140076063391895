import { api } from './api'

type Chargeback = {
  chargeback_date: string
  created_at: string
  funds_transaction_id: string
  id: string
  updated_at: string | null
  user_id: string
  username: string
  name: string
  surname: string
  midname: string
}

type SendChargeback = {
  transaction_id: string
  chargeback_date: string
}

const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getChargeback: builder.query<Chargeback[], string>({
      query: (loanId) => ({
        url: `crm/v1/payments/chargeback/${loanId}`,
      }),
      providesTags: (_result, _err, id) => [{ type: 'Chargeback', id }],
    }),
    setChargeback: builder.mutation<void, SendChargeback>({
      query: (body) => ({
        url: `crm/v1/payments/chargeback`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => ['Chargeback'],
    }),
  }),
})

export const { useGetChargebackQuery, useSetChargebackMutation } = paymentsApi
