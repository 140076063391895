import { createSlice } from '@reduxjs/toolkit'
import { api } from './api'

type UserInfo = {
  id: string
  status: string
  username: string
  surname: string
  name: string
  midname: string
  roles: string[]
  permissions: string[]
  asterisk: {
    login: string
    password: string
    url: string
  }
}

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserInfo, void>({
      query: () => 'crm/v1/keycloak/users/info',
      providesTags: () => ['User'],
    }),
    getUserStatuses: builder.query<string[], void>({
      query: () => 'crm/v1/keycloak/users/statuses',
      transformResponse: ({ result }) => result,
    }),
    setUserStatus: builder.mutation<string, void>({
      query: (status) => ({
        url: 'crm/v1/keycloak/users/status',
        method: 'POST',
        body: { status },
      }),
      invalidatesTags: () => ['User'],
    }),
  }),
})

const authSlice = createSlice({
  name: 'auth',
  initialState: { userInfo: null } as { userInfo: UserInfo | null },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getUserInfo.matchFulfilled,
      (state, { payload }) => {
        state.userInfo = payload
      },
    )
  },
})

export const authReducer = authSlice.reducer

export const {
  useGetUserInfoQuery,
  useGetUserStatusesQuery,
  useSetUserStatusMutation,
} = authApi
