import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Table4Columns from './Table4Columns'
import Widget from './LoanWidget'
import Client from '../../../client'
import { useGetLoanCardQuery } from 'src/services/loans'

const columnsDescriptionDefault = [
  ['id'],
  ['status'],
  ['loan_issue_date', 'loan_close_date'],
  ['loan_issue_card'],
  ['term', 'loan_repayment_date'],
  ['amount_writeoff', 'prolongation_count'],
  ['amount_debt', 'dpd'],

  ['amount_principal_debt'],
  ['amount_interest_debt'],
  ['amount_insurance_debt'],
  ['amount_premium_account_debt'],
  ['amount_penalty_debt'],

  ['amount_interest_accrued', 'amount_interest_paid'],
  ['amount_principal_accrued', 'amount_principal_paid'],
  ['amount_issuance_fee_accrued', 'amount_issuance_fee_paid'],
  ['amount_premium_account_accrued', 'amount_premium_account_paid'],
  ['amount_insurance_accrued', 'amount_insurance_paid'],
  ['amount_penalty_accrued', 'amount_penalty_paid'],
  ['amount_ext_fee_accrued', 'amount_ext_fee_paid'],
  ['amount_repayment_fee_accrued', 'amount_repayment_fee_paid'],

  ['interest', 'interest_rate_overdue'],
  ['amount_overpayment'],
  ['extension_available', 'amount_for_extension'],
]

const fieldsDescription = {
  id: { isLink: true, linkFieldName: 'loan_link' },
  new_line: { isEmpty: true, newLine: true },

  term: { label: 'approved_period' },

  loan_repayment_date: { fieldName: 'repayment_date', isDate: true },
  loan_close_date: { fieldName: 'closing_date', isDate: true },
  loan_issue_date: { fieldName: 'issue_date', isDate: true },

  amount_for_extension: { isAmount: true },
  amount_writeoff: { isAmount: true },
  amount_debt: { isAmount: true },

  amount_principal_accrued: { isAmount: true },
  amount_principal_paid: { isAmount: true },

  amount_interest_accrued: { isAmount: true },
  amount_interest_paid: { isAmount: true },

  amount_issuance_fee_accrued: { isAmount: true },
  amount_issuance_fee_paid: { isAmount: true },

  amount_penalty_accrued: { isAmount: true },
  amount_penalty_paid: { isAmount: true },

  amount_ext_fee_accrued: { isAmount: true },
  amount_ext_fee_paid: { isAmount: true },

  amount_repayment_fee_accrued: { isAmount: true },
  amount_repayment_fee_paid: { isAmount: true },

  amount_premium_account_accrued: { isAmount: true },
  amount_premium_account_paid: { isAmount: true },

  amount_insurance_accrued: { isAmount: true },
  amount_insurance_paid: { isAmount: true },

  amount_overpayment: { isAmount: true },

  interest: { label: 'loan_interest', isAmount: true, decimals: 3 },
  interest_rate_overdue: { isAmount: true, decimals: 3 },

  sale_date: { fieldName: 'sale_date', isDate: true },
  company_short_name: { label: 'company_short_name' },

  amount_principal_debt: { isAmount: true },
  amount_interest_debt: { isAmount: true },
  amount_premium_account_debt: { isAmount: true },
  amount_insurance_debt: { isAmount: true },
  amount_penalty_debt: { isAmount: true },
}

const EntityContent = ({ entity, style, columnsDescription }) => {
  const { data: loan_issue_card } = useGetLoanCardQuery(entity?.id, {
    skip: entity?.id == null,
  })
  const { t } = useTranslation()
  const [detailsUrl, setDetailsUrl] = useState('')
  const [dropPA, setDropPA] = useState(false)
  const [dropInsurance, setDropInsurance] = useState(false)
  const [contractStatus, setContractStatus] = useState('')
  const [contractError, setContractError] = useState('')
  const [contractLoading, setContractLoading] = useState(false)

  useEffect(() => {
    console.log('loan =>', entity)
  }, [entity?.id])

  let ext_details = {}
  if (entity.ext_details) {
    ext_details = {
      extension_available: entity.ext_details.extension_available,
      amount_for_extension: entity.ext_details.amount_for_extension,
    }
  }

  const saveContract = () => {
    setContractStatus('')
    setContractError('')
    setContractLoading(true)
    Client.reloadContract(entity.id, dropPA, dropInsurance)
      .then((res) => {
        setContractStatus(t('saved'))
      })
      .catch((err) => {
        setContractError(err.toString())
      })
      .finally(() => setContractLoading(false))
  }

  const downloadDetails = (url) => {
    console.log('file url =>', url)

    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = `credit-details-${entity?.id}.xlsx`
    a.click()
    document.body.removeChild(a)
  }

  const download = (loanId) => {
    if (detailsUrl) {
      downloadDetails(detailsUrl)
      return
    }

    Client.getLoanCredit(loanId)
      .then((file) => file.blob())
      .then((blob) => window.URL.createObjectURL(blob))
      .then((url) => {
        setDetailsUrl(url)
        downloadDetails(url)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Row>
      <Col xxl="8" xl="12" className="order-2 order-xxl-1">
        <div className="d-flex align-items-center gap-3">
          <div
            className="note text-info text-decoration-underline mt-2 mb-3"
            role="button"
            onClick={() => download(entity?.id)}
          >
            {t('download_loan_detail')}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center gap-3 mb-3 rounded border border-1 p-2">
          <div className="d-flex flex-row align-items-center">
            <Input
              id="drop-pa-id"
              type="checkbox"
              checked={dropPA}
              className="me-2"
              onChange={(e) => {
                setDropPA(e.target.checked)
                setContractError('')
                setContractStatus('')
              }}
            />
            <div className="mt-1">{t('drop_pa')}</div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <Input
              id="drop-insurance-id"
              type="checkbox"
              checked={dropInsurance}
              className="me-2"
              onChange={(e) => {
                setDropInsurance(e.target.checked)
                setContractError('')
                setContractStatus('')
              }}
            />
            <div className="mt-1">{t('drop_insurance')}</div>
          </div>
          <Button size="sm" disabled={contractLoading} onClick={saveContract}>
            {t('Save')}
          </Button>
          {contractLoading && (
            <small className="text-warning">{t('loading')}</small>
          )}
          {contractStatus && (
            <small className="text-success">{contractStatus}</small>
          )}
          {contractError && (
            <small className="text-danger">{contractError}</small>
          )}
        </div>
        <Table4Columns
          columnsDescription={columnsDescription || columnsDescriptionDefault}
          fieldsDescription={fieldsDescription}
          className="table-sm align-middle mb-0"
          entity={{
            ...entity,
            loan_issue_card,
            loan_link: `/loan-view?id=${entity.id}`,
            ...ext_details,
          }}
        />
      </Col>
      <Col
        xxl="4"
        xl="12"
        className="order-1 order-xxl-2 d-flex align-items-center justify-content-center"
      >
        {!!entity && <Widget loan={entity} style={{ marginLeft: -70 }} />}
      </Col>
    </Row>
  )
}

export default EntityContent
