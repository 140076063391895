import React, { useEffect, useState } from 'react'
import UiContent from 'src/Components/Common/UiContent'
import { Card, CardBody, Table } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from 'src/store/users/action'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import { Link } from 'react-router-dom'
import LoadingPage from '../LoadingPage'

export const Users = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [pageReady, setPageReady] = useState(false)
  const [filterQuery, setFilterQuery] = useState('')
  const title = t('Users')
  document.title = title

  const { list, listLoading, listError } = useSelector((state) => ({
    list: state.users.list,
    listLoading: state.users.listLoading,
    listError: state.users.listError,
  }))

  useEffect(() => {
    dispatch(getUsers())
    setPageReady(true)
  }, [dispatch])

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (!pageReady || listLoading) {
    return <LoadingPage backLink={'/products'} title={title} />
  }

  const minimizeTextStyle = {
    textOverflow: 'ellipsis',
    maxWidth: 200,
    overflow: 'hidden',
  }
  const metaBar = <MetaBar createLink={'/user-edit'} />

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            {metaBar}
            <div style={{ marginTop: '10px' }} className="gridjs-head">
              <div className="gridjs-search">
                <input
                  type="search"
                  className="gridjs-input gridjs-search-input"
                  id="iconInput"
                  placeholder="Search..."
                  onChange={(event) => {
                    const filter = event.target.value
                    setFilterQuery(filter)
                  }}
                />
              </div>
            </div>
            <Table
              hover
              className="table-sm align-middle table-nowrap mb-0"
              style={{ width: '900px' }}
            >
              <thead>
                <tr>
                  <th className="ps-3" scope="col">
                    {t('username')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {list
                  .filter((item) => item.username.indexOf(filterQuery) > -1)
                  .map((user) => (
                    <tr key={user.id}>
                      <td
                        className="ps-3"
                        style={{ ...minimizeTextStyle }}
                        key={user.username}
                      >
                        <Link to={`/user-view?id=${user.username}`}>
                          {user.username}
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
