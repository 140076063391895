import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

import {
  mapStatusToClassName,
  mapStatusToName,
} from 'src/Components/Common/EntitiesView/Communications'
import { LIST_LIMIT } from 'src/client'
import LimitNotice from '../LimitNotice'
import model from 'src/model'
import List from './List'
import Loader from '../Loader'
import { UseQueryHookResultData } from 'src/services/api'
import { useGetClaimQuery, useGetClaimsQuery } from 'src/services/claims'
import { useGetCommunicationsQuery } from 'src/services/communications'
import { HistoryClaimsView } from 'src/pages/Admin/Claims/HistoryClaimsView'

type Props = {
  entityId: string
  profileId: string
  visible: boolean
}

const EntityContent: FC<Props> = ({ entityId, profileId, visible }) => {
  const { t } = useTranslation()

  const [active, setActive] = useState('email-incoming')

  const {
    data: claims,
    isFetching: isClaimsFetching,
    isSuccess: isClaimsSuccess,
  } = useGetClaimsQuery(
    {
      clientId: entityId,
      limit: LIST_LIMIT,
      offset: 0,
    },
    {
      skip: entityId == null || !visible,
    },
  )

  const {
    data: communications,
    isFetching: isCommunicationFetching,
    isSuccess: isCommunicationSuccess,
  } = useGetCommunicationsQuery(
    {
      profile_id: profileId,
      type: active,
      limit: LIST_LIMIT,
      offset: 0,
    },
    {
      skip: entityId == null || !visible,
    },
  )

  const [communication, setCommunication] =
    useState<UseQueryHookResultData<typeof useGetCommunicationsQuery>[0]>()

  const [claim, setClaim] =
    useState<UseQueryHookResultData<typeof useGetClaimQuery>>()

  const [showModal, setShowModal] = useState(false)

  const handleCommunicationClick = useCallback(
    (listItem: UseQueryHookResultData<typeof useGetCommunicationsQuery>[0]) => {
      setCommunication(listItem)
      setShowModal(true)
    },
    [],
  )

  const handleClaimClick = useCallback(
    (listItem: UseQueryHookResultData<typeof useGetClaimQuery>) => {
      setClaim(listItem)
      setShowModal(true)
    },
    [],
  )

  const fieldsDescriptionClaim = useMemo(
    () => ({
      id: {
        label: ' ',
        mappingValue: () => <i className="ri-eye-line fs-15" />,
        isButton: true,
        onClick: handleClaimClick,
      },
      claim_number: {},
      claim_topic: {},
      status: {},
      created_at: { isDateTime: true },
      closed_at: { isDateTime: true },
    }),
    [handleClaimClick],
  )

  const fieldsDescriptionCommunication = useMemo(
    () => ({
      id: {
        label: ' ',
        mappingValue: () => <i className="ri-eye-line fs-15" />,
        isButton: true,
        onClick: handleCommunicationClick,
      },
      status: {
        mappingValue: (value: string) => t(mapStatusToName(value)),
        mappingClassName: (value: string) => mapStatusToClassName(value),
      },
      profile_id: {},
      created_at: { isDateTime: true },
      updated_at: { isDateTime: true },
    }),
    [handleCommunicationClick, t],
  )

  const toggle = () => setShowModal(false)

  return (
    <>
      <Nav tabs className="nav-tabs nav-border-top mb-3">
        <NavItem>
          <NavLink
            active={active === 'email-incoming'}
            onClick={() => setActive('email-incoming')}
            href="#"
          >
            {t('Email incoming')}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={active === 'email-outgoing'}
            onClick={() => setActive('email-outgoing')}
            href="#"
          >
            {t('Email outgoing')}
          </NavLink>
        </NavItem>
      </Nav>

      {(isClaimsFetching || isCommunicationFetching) && <Loader />}

      {active === 'email-outgoing' ? (
        isCommunicationSuccess && communications!.length > 0 ? (
          <>
            <LimitNotice limit={model.LIST_LIMIT} />
            <List
              list={communications}
              fieldsDescription={fieldsDescriptionCommunication}
              style={undefined}
              className={undefined}
              hover={undefined}
              actions={undefined}
            />
          </>
        ) : (
          <div style={{ marginBottom: '10px' }}>
            <dl className="row mb-0">
              <small>{t('No data')}</small>
            </dl>
          </div>
        )
      ) : isClaimsSuccess && claims!.length > 0 ? (
        <>
          <LimitNotice limit={model.LIST_LIMIT} />
          <List
            list={claims?.filter((claim) => !claim.parent_claim_uuid)}
            fieldsDescription={fieldsDescriptionClaim}
            style={undefined}
            className={undefined}
            hover={undefined}
            actions={undefined}
          />
        </>
      ) : (
        <div style={{ marginBottom: '10px' }}>
          <dl className="row mb-0">
            <small>{t('No data')}</small>
          </dl>
        </div>
      )}

      <Modal isOpen={showModal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{t('History claims')}</ModalHeader>

        <ModalBody>
          <HistoryClaimsView
            claim={claim}
            communication={communication}
            active={active}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default EntityContent
