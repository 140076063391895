import { useTranslation } from 'react-i18next'

import CircleChart from './Chart'

const config = {
  amount_principal_debt: { color: '#ed5e5e' }, //danger
  amount_interest_debt: { color: '#BADBAD' }, //green
  amount_premium_account_debt: { color: '#B39F7A' }, //coffee
  amount_insurance_debt: { color: '#EFCDB8' }, //lite pink
  amount_penalty_debt: { color: '#7FC7FF' }, //blue
  amount_principal_paid: { color: '#6691e7' }, //primary
  amount_issuance_fee_paid: { color: '#e8bc52' }, //warning
  amount_penalty_paid: { color: '#50c3e6' }, //info
  amount_interest_paid: { color: '#13c56b' }, //success
  amount_ext_fee_paid: { color: '#865ce2' }, //secondary
  amount_premium_account_paid: { color: '#363d48' }, //dark
  amount_insurance_paid: { color: '#405189' }, //indigo
  amount_repayment_fee_paid: { color: '#f672a7' }, //pink
  amount_writeoff: { color: '#000' }, //
}

const Widget = ({ loan, style }) => {
  const { t } = useTranslation()

  return (
    <div
      id="portfolio_donut_charts"
      dir="ltr"
      className="d-flex flex-xxl-column flex-xl-row gap-0 align-items-center"
      style={style}
    >
      {loan && (
        <CircleChart
          dataColors={`[
          "${config['amount_principal_debt'].color}",
          "${config['amount_interest_debt'].color}",
          "${config['amount_premium_account_debt'].color}",
          "${config['amount_insurance_debt'].color}",
          "${config['amount_penalty_debt'].color}",
          "${config['amount_principal_paid'].color}",
          "${config['amount_issuance_fee_paid'].color}",
          "${config['amount_penalty_paid'].color}",
          "${config['amount_interest_paid'].color}",
          "${config['amount_ext_fee_paid'].color}",
          "${config['amount_premium_account_paid'].color}",
          "${config['amount_insurance_paid'].color}",
          "${config['amount_repayment_fee_paid'].color}",
          "${config['amount_writeoff'].color}"
        ]`}
          series={[
            loan.amount_principal_debt || 0,
            loan.amount_interest_debt || 0,
            loan.amount_premium_account_debt || 0,
            loan.amount_insurance_debt || 0,
            loan.amount_penalty_debt || 0,
            loan.amount_principal_paid || 0,
            loan.amount_issuance_fee_paid || 0,
            loan.amount_penalty_paid || 0,
            loan.amount_interest_paid || 0,
            loan.amount_ext_fee_paid || 0,
            loan.amount_premium_account_paid || 0,
            loan.amount_insurance_paid || 0,
            loan.amount_repayment_fee_paid || 0,
            loan.amount_writeoff || 0,
          ]}
          labels={[
            t('amount_principal_debt'),
            t('amount_interest_debt'),
            t('amount_premium_account_debt'),
            t('amount_insurance_debt'),
            t('amount_penalty_debt'),
            t('amount_principal_paid'),
            t('amount_issuance_fee_paid'),
            t('amount_penalty_paid'),
            t('amount_interest_paid'),
            t('amount_ext_fee_paid'),
            t('amount_premium_account_paid'),
            t('amount_insurance_paid'),
            t('amount_repayment_fee_paid'),
            t('amount_writeoff'),
          ]}
          total={loan.amount_debt}
        />
      )}
      <div className="align-items-center mx-n5">
        <div className="vstack">
          {Object.keys(config).map((item, key) => (
            <div key={key}>
              <div className="flex-grow-1 ms-2">
                <p className="fs-12 mb-0 text-muted">
                  <i
                    className={'mdi mdi-circle fs-10 align-middle me-1 '}
                    style={{ color: config[item].color }}
                  ></i>
                  {t(item)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Widget
