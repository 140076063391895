import UiContent from 'src/Components/Common/UiContent'
import React, { useEffect, useState } from 'react'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MetaBar from 'src/Components/Common/MetaBar'
import {
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
  Card,
  Container,
  FormGroup,
  Spinner,
  FormFeedback,
  Alert,
  Button,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import { formatAPIDate } from 'src/utils'
import { getLoans, updateLoan } from 'src/store/loans/action'
import Client from 'src/client'

const inputInitials = {
  prolongation_days: { label: 'prolongation_days' },
  writeoff_type: { label: 'writeoff_type', initial: '-', isList: true },
  writeoff_amount: { label: 'writeoff_amount' },
}

export const LoanEdit = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const title = t('Edit loan')
  const [initialFormValues, setInitialFormValues] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [initials, setInitials] = useState(null)
  const [types, setTypes] = useState([])
  const [paramError, setParamError] = useState(null)
  const [updateSuccess, setUpdateSuccess] = useState(null)
  document.title = title
  const bread = <BreadCrumb title={title} />
  const loading = (
    <div className="p-5 text-center">
      <Spinner color="primary">{t('Loading...')}</Spinner>
    </div>
  )

  const { loan, listLoading, listError, updateLoading, updateError } =
    useSelector((state) => ({
      loan:
        state.loans.list && state.loans.list.length
          ? state.loans.list[0]
          : null,
      listLoading: state.loans.listLoading,
      listError: state.loans.listError,
      updateError: state.loans.updateError,
      updateLoading: state.loans.updateLoading,
    }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setPageReady(false)
    if (id) dispatch(getLoans(id))
    else setParamError('No loan id found')
  }, [])

  useEffect(() => {
    if (!loan) return

    const initialFormValues = {}
    const initials = {}
    Object.keys(inputInitials).forEach((key) => {
      initials[key] = { ...inputInitials[key], initial: loan[key] }
    })
    Object.keys(inputInitials).forEach((key) => {
      initialFormValues[key] = loan[key]
    })
    setInitials(initials)
    setInitialFormValues(initialFormValues)

    Client.getLoanWriteoffTypes()
      .then((types) => {
        console.log('types =>', types)
        initials.writeoff_type.options = [
          { name: '-', value: '-' },
          ...types.map((type) => ({
            ...type,
            name: type.name,
            value: type.id,
          })),
        ]
        setTypes(types)
      })
      .catch(() => {
        setTypes([])
      })

    setPageReady(true)
  }, [loan])

  useEffect(() => {}, [types])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({
      prolongation_days: Yup.number(),
      writeoff_amount: Yup.number(),
      writeoff_type: Yup.string(),
    }),
    // onSubmit: (values) => {
    //     const updatedLoan = {id: loan.id, ...values};
    //     const resultObj = {id: updatedLoan.id}
    //     if (updatedLoan.prolongation_days !== undefined) {
    //         resultObj.prolongation = {days: Number(updatedLoan.prolongation_days.replaceAll(',', '.'))}
    //     }
    //
    //     if (updatedLoan.writeoff_amount !== undefined) {
    //         resultObj.writeoff = {amount: Number(updatedLoan.writeoff_amount.replaceAll(',', '.'))}
    //     }
    //     dispatch(updateLoan(resultObj));
    //     setUpdateSuccess(true);
    // },
  })

  let metaBar = <MetaBar backLink={`/loan-view?id=${loan?.id}`} entity={loan} />

  if (paramError || listError) {
    return (
      <React.Fragment>
        <UiContent />
        <div className="page-content">
          <Container fluid={true}>
            {bread}
            <Alert color="warning">
              <strong> {!!paramError && paramError.toString()} </strong>
              <strong> {!!listError && listError.toString()} </strong>
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  if (!pageReady || listLoading) {
    return (
      <React.Fragment>
        <UiContent />
        <div className="page-content">
          <Container fluid={true}>
            {bread}
            {loading}
          </Container>
        </div>
      </React.Fragment>
    )
  }

  return (
    <>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Container fluid={true} className="mt-4">
              <Row>
                <Col lg={12}>
                  <Form
                    id="formElem"
                    className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      {Object.keys(initials).map((fieldKey, i) => {
                        const htmlForId = `validation-${fieldKey}`
                        return (
                          <Col md="4" key={`${fieldKey}`}>
                            <FormGroup className="mb-3">
                              <Label htmlFor={htmlForId}>
                                {t(initials[fieldKey].label)}
                              </Label>
                              {initials[fieldKey].options &&
                              initials[fieldKey].options.length ? (
                                <select
                                  name={`${fieldKey}`}
                                  id={htmlForId}
                                  className="form-select form-select-sm"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values[fieldKey] || ''}
                                >
                                  {initials[fieldKey].options.map((option) => (
                                    <option
                                      key={option.name}
                                      defaultValue={option.defaultValue}
                                    >
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              ) : initials[fieldKey].date ? (
                                <Flatpickr
                                  name={`${fieldKey}`}
                                  id={htmlForId}
                                  value={validation.values[fieldKey] || ''}
                                  className="form-control form-control-sm"
                                  options={{
                                    altInput: true,
                                    allowInput: true,
                                    dateFormat: 'Y-m-d',
                                    altFormat: 'd.m.Y',
                                    onChange: (selectedDates, dateStr) => {
                                      console.log(
                                        'selectedDate, dateStr',
                                        selectedDates,
                                        dateStr,
                                      )
                                      validation.setFieldValue(
                                        fieldKey,
                                        formatAPIDate(selectedDates[0]),
                                      )
                                    },
                                  }}
                                />
                              ) : (
                                <>
                                  <Input
                                    name={`${fieldKey}`}
                                    placeholder={initials[fieldKey].placeholder}
                                    type="text"
                                    className="form-control form-control-sm"
                                    id={htmlForId}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values[fieldKey] || ''}
                                    invalid={
                                      !!(
                                        validation.touched[fieldKey] &&
                                        validation.errors[fieldKey]
                                      )
                                    }
                                  />
                                  <Button
                                    style={{ marginTop: '10px' }}
                                    color="success"
                                    onClick={() => {
                                      if (
                                        validation.values[fieldKey] !==
                                        undefined
                                      ) {
                                        const resultObj = { id: loan.id }
                                        if (fieldKey === 'prolongation_days') {
                                          resultObj.prolongation = {
                                            days: Number(
                                              validation.values[
                                                fieldKey
                                              ].replaceAll(',', '.'),
                                            ),
                                          }
                                        }
                                        if (fieldKey === 'writeoff_amount') {
                                          resultObj.writeoff = {
                                            amount: Number(
                                              validation.values[
                                                fieldKey
                                              ].replaceAll(',', '.'),
                                            ),
                                            writeoff_type:
                                              validation.values[
                                                'writeoff_type'
                                              ] !== '-'
                                                ? types.find(
                                                    (t) =>
                                                      t.name ===
                                                      validation.values[
                                                        'writeoff_type'
                                                      ],
                                                  )?.id
                                                : null,
                                          }
                                        }
                                        dispatch(updateLoan(resultObj))
                                        setUpdateSuccess(true)
                                      }
                                    }}
                                  >
                                    {t('Update')}
                                  </Button>
                                </>
                              )}
                              {validation.touched[fieldKey] &&
                              validation.errors[fieldKey] ? (
                                <FormFeedback type="invalid">
                                  {validation.errors[fieldKey]}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        )
                      })}
                    </Row>
                    {!!updateError && (
                      <Alert color="warning">
                        <strong> {updateError.toString()} </strong>
                      </Alert>
                    )}

                    {!updateError && updateSuccess && (
                      <Alert color="success">
                        <strong> {t('Operation success')} </strong>
                      </Alert>
                    )}
                    {updateLoading && loading}
                  </Form>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
