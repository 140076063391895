import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
          <footer className="footer">
            <Container fluid>
              <Row>
                <small>
                  {new Date().getFullYear()} © Кибертех.
                </small>
              </Row>
            </Container>
          </footer>
        </React.Fragment>
    );
};

export default Footer;
