import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import ErrorPage from 'src/pages/Admin/ErrorPage'
import LoadingPage from 'src/pages/Admin/LoadingPage'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import List from 'src/Components/Common/EntitiesView/List'
import LimitNotice from 'src/Components/Common/LimitNotice'
import MetaBar from 'src/Components/Common/MetaBar'
import model from 'src/model'
import { useGetChatTemplatesQuery } from 'src/services/chats'

export const ChatsTemplates = () => {
  const { data: chatTemplates, error, isError } = useGetChatTemplatesQuery()

  const { t } = useTranslation()

  const title = t('Chat templates')

  if (isError) {
    return <ErrorPage title={title} error={error} backLink={undefined} />
  }

  if (!chatTemplates) {
    return <LoadingPage title={title} backLink={undefined} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar createLink={'/chats/templates/edit'} />
          <LimitNotice limit={model.LIST_LIMIT} />
          <List
            list={chatTemplates.map((promotion) => ({
              ...promotion,
              id_link: `/chats/templates/${promotion.id}`,
            }))}
            style={{ width: 'fit-content' }}
            fieldsDescription={{
              id: {
                label: ' ',
                mappingValue: () => <i className="ri-eye-line fs-15" />,
                isLink: true,
                linkFieldName: 'id_link',
              },
              created_at: { isDate: true },
              updated_at: { isDate: true },
              name: {},
              text_template: {},
            }}
            hover
          />
        </CardBody>
      </Card>
    </div>
  )
}
