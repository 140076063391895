import { useTranslation } from 'react-i18next'
import { Card, CardBody, Row, Col, Label, Input } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../../ErrorPage'
import LoadingPage from '../../LoadingPage'
import { useGetTextTemplateQuery } from 'src/services/templatesClaim'
import { useSearchParams } from 'react-router-dom'
import model from 'src/model'

export const TemplatesClaimView = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const {
    data: template,
    isError,
    error,
    isLoading,
  } = useGetTextTemplateQuery(
    {
      id: Number(searchParams.get('id')!),
    },
    {
      skip: searchParams.get('id') == null,
    },
  )
  const title = `${t('Templates claim')}: ${t('View')}`

  if (isError) {
    return <ErrorPage title={title} error={error} backLink={undefined} />
  }

  if (isLoading) {
    return <LoadingPage title={title} backLink={undefined} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            createLink={undefined}
            backLink={'/claim-templates'}
            editLink={`/claim-templates-edit/?id=${template?.id}`}
            entity={template}
            buttons={undefined}
            disableBorder={undefined}
          />
          <Row className="mt-3">
            {template &&
              Object.keys(template).map((fieldName) => {
                return (
                  <Col className={'col-12 mt-2 mb-2'} key={`${fieldName}`}>
                    <Label htmlFor={fieldName}>{t(fieldName)}</Label>
                    <Input
                      type="text"
                      className="form-control form-control-sm"
                      value={model.fieldValueToText(
                        fieldName,
                        template,
                        template,
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
