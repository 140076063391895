import cx from 'classnames'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  // Nav,
  // NavItem,
  // NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { ENTITY_TYPE } from 'src/client'
import { selectUnreadMessages, useGetMyChatsQuery } from 'src/services/chats'
import { useDispatch, useSelector } from 'src/store'
import { getEntities } from 'src/store/actions'
import { utcToLocal } from 'src/utils'

export const NotificationDropdown: FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useGetMyChatsQuery()

  const unreadMessages = useSelector(selectUnreadMessages)

  useEffect(() => {
    // TODO: move API call to services/collectors.ts
    // TODO: replace selector with rtk query hook
    // dispatch(getEntities(ENTITY_TYPE.COLLECTORS))
    // TODO: move API call to services/auth.ts
    // TODO: replace selector with rtk query hook
    dispatch(getEntities(ENTITY_TYPE.PERMISSIONS))
  }, [dispatch])

  const [isOpen, setIsOpen] = useState(false)
  const toggleNotificationDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  // const [activeTab, setActiveTab] = useState('1')
  // const toggleTab = (tab: any) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab)
  //   }
  // }

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggleNotificationDropdown}
      className="topbar-head-dropdown ms-1 header-item"
    >
      <DropdownToggle
        type="button"
        tag="button"
        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
      >
        <i className={`bx ${isOpen ? 'bxs-bell' : 'bx-bell'} fs-22`}></i>

        <span
          className={cx(
            'position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger',
            { 'opacity-0': unreadMessages.length === 0 },
          )}
        >
          {unreadMessages.length}
          <span className="visually-hidden">unread notifications</span>
        </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
        <div className="dropdown-head bg-primary bg-pattern rounded-top">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 fs-16 fw-semibold text-white">
                  {t('Notifications')}
                </h6>
              </Col>
            </Row>
          </div>

          {/* <div className="px-2 pt-2"> */}
          {/*   <Nav className="nav-tabs dropdown-tabs nav-tabs-custom"> */}
          {/*     <NavItem> */}
          {/*       <NavLink */}
          {/*         href="#" */}
          {/*         className={classnames({ active: activeTab === '1' })} */}
          {/*         onClick={() => { */}
          {/*           toggleTab('1') */}
          {/*         }} */}
          {/*       > */}
          {/*         {t('All')} ({unreadMessages.length || 0}) */}
          {/*       </NavLink> */}
          {/*     </NavItem> */}
          {/*   </Nav> */}
          {/* </div> */}
        </div>

        <TabContent
          // activeTab={activeTab}
          activeTab="1"
        >
          <TabPane tabId="1" className="py-2 ps-2">
            <div
              style={{ maxHeight: '300px', overflow: 'auto' }}
              className="pe-2"
            >
              {unreadMessages.map((message) => (
                <Link
                  to={`/chats/${message.chat_id}`}
                  // className="stretched-link"
                  onClick={toggleNotificationDropdown}
                >
                  <div
                    className="text-reset notification-item d-block dropdown-item position-relative"
                    key={message.id}
                  >
                    <div className="d-flex">
                      <div className="avatar-xs me-3 flex-shrink-0">
                        <span className="avatar-title bg-soft-info text-info rounded-circle fs-16">
                          <i className="bx bx-message-add"></i>
                        </span>
                      </div>
                      <div className="flex-1">
                        <h6
                          className="mt-0 lh-base mb-0 text-truncate"
                          style={{ width: '220px' }}
                        >
                          {message.content_type === 'text'
                            ? message.content
                            : message.content_type === 'image'
                            ? t('Image')
                            : ''}
                        </h6>
                        <p className="mb-0 fs-11 fw-medium text-muted">
                          <span>
                            <i className="mdi mdi-clock-outline"></i>{' '}
                            {moment(utcToLocal(message.created_at))
                              .locale('ru')
                              .fromNow()}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>

            {unreadMessages.length === 0 && <NoNotifications />}
          </TabPane>
          <TabPane tabId="3" className="p-4">
            <NoNotifications />
          </TabPane>
        </TabContent>
      </DropdownMenu>
    </Dropdown>
  )
}

const NoNotifications: FC = () => {
  const { t } = useTranslation()
  return (
    <div className="text-center mt-2">
      <h6 className="fs-18 fw-semibold lh-base">
        {t('You have no notifications! For now...')}
      </h6>
    </div>
  )
}
