import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import List from 'src/Components/Common/EntitiesView/List'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import MetaBar from 'src/Components/Common/MetaBar'
import Client from 'src/client'

import { useTranslation } from 'react-i18next'

import { getEntities } from 'src/store/actions'
import DownloadDossier from 'src/Components/Common/EntitiesView/DownloadDossier'

const fieldsDescription = {
  id: { isLink: true, linkFieldName: 'id_link' },
  full_name: { label: 'полное название' },
  short_name: { label: 'сокращенное название' },
  registry_email: { label: 'email для выгрузки реестров' },
  communication_phone: { label: 'телефон для связи' },
  is_external: { label: 'компания', isExternal: true },
}

export const Agencies = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { list, listLoading, listError } = useSelector((state) => {
    return {
      list: state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].list,
      listError:
        state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].listError,
      listLoading:
        state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].listLoading,
    }
  })

  useEffect(() => {
    dispatch(getEntities(Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL))
  }, [dispatch])

  console.log('Agencies =>', list)
  const title = t('Agencies')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  const downloadDossier = <DownloadDossier />

  const metaBar = (
    <MetaBar
      createLink={'/external-agencies-edit'}
      buttons={[downloadDossier]}
    />
  )

  const bread = <BreadCrumb title={title} />

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}

        <Card>
          <CardBody>
            {metaBar}
            <div className="table-responsive table-card mt-3">
              <List
                style={{ width: 'fit-content' }}
                list={list.map((item) => ({
                  ...item,
                  id_link: `/external-agencies-view?id=${item.id}`,
                }))}
                fieldsDescription={fieldsDescription}
                className="table-sm align-middle table-nowrap mb-0"
                hover
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
