import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import { getEntities } from 'src/store/actions'

import Client from 'src/client'
import model from 'src/model'

import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'

const fieldsDescription = {
  full_name: {
    label: 'full_name',
    initial: '',
    placeholder: '',
    fullWidth: true,
  },
  short_name: {
    label: 'short_name',
    initial: '',
    placeholder: '',
    fullWidth: true,
  },

  inn: { label: 'inn', initial: '', placeholder: '', fullWidth: true },
  kpp: { label: 'kpp', initial: '', placeholder: '', fullWidth: true },
  ogrn: { label: 'ogrn', initial: '', placeholder: '', fullWidth: true },

  communication_phone: {
    label: 'communication_phone',
    initial: '',
    placeholder: '',
    fullWidth: true,
  },
  customer_phone: {
    label: 'customer_phone',
    initial: '',
    placeholder: '',
    fullWidth: true,
  },

  communication_email: {
    label: 'communication_email',
    initial: '',
    placeholder: '',
    fullWidth: true,
  },
  customer_support_email: {
    label: 'customer_support_email',
    initial: '',
    placeholder: '',
    fullWidth: true,
  },
  registry_email: {
    label: 'registry_email',
    initial: '',
    placeholder: '',
    fullWidth: true,
  },

  postal_address: {
    label: 'postal_address',
    initial: '',
    placeholder: '',
    fullWidth: true,
  },
  legal_address: {
    label: 'legal_address',
    initial: '',
    placeholder: '',
    fullWidth: true,
  },

  is_external: {
    label: 'is_external',
    initial: '',
    placeholder: '',
    fullWidth: true,
    isExternal: true,
  },
}

export const AgencyView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const { entity, entityLoading, entityError } = useSelector((state) => ({
    entity:
      state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].entity || null,
    entityLoading:
      state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].entityLoading,
    entityError:
      state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].entityError,
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    if (id) dispatch(getEntities(Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL, id))
    else setParamError('No entity id found')
  }, [])

  useEffect(() => {
    setPageReady(!!entity)
  }, [entity])

  const title = `${t('Agencies')}: ${t('View')}`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const metaBar = (
    <MetaBar
      backLink={'/external-agencies'}
      editLink={entity ? `/external-agencies-edit?id=${entity.id}` : null}
      entity={entity}
    />
  )

  if (paramError || entityError) {
    return (
      <ErrorPage
        backLink={'/external-agencies'}
        title={title}
        error={[paramError, entityError]}
      />
    )
  }

  if (!pageReady || entityLoading) {
    return <LoadingPage backLink={'/external-agencies'} title={title} />
  }

  console.log('entity =>', entity)
  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className="mt-3">
              {Object.keys(fieldsDescription).map((fieldName) => {
                return (
                  <Col
                    className={
                      fieldsDescription[fieldName].fullWidth
                        ? 'col-12 mt-3'
                        : 'col-auto'
                    }
                    key={`${fieldName}`}
                  >
                    <Label htmlFor={fieldName}>{t(fieldName)}</Label>
                    <Input
                      type="text"
                      className="form-control form-control-sm"
                      value={model.fieldValueToText(
                        fieldName,
                        entity,
                        fieldsDescription,
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
