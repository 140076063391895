import { useTranslation } from 'react-i18next'
import {
  Card,
  CardBody,
  Input,
  Label,
  Row,
  Form,
  Col,
  FormGroup,
  Button,
  Alert,
} from 'reactstrap'
import { useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { autoResizeInput } from 'src/utils'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../../ErrorPage'
import LoadingPage from '../../LoadingPage'
import {
  useAddTextTemplateMutation,
  useGetTextTemplateQuery,
  useUpdateTextTemplateMutation,
} from 'src/services/templatesClaim'
import { UseQueryHookResultData } from 'src/services/api'

export const TemplatesClaimEdit = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const {
    data: template,
    isError,
    error,
    isFetching,
  } = useGetTextTemplateQuery(
    {
      id: Number(searchParams.get('id')!),
    },
    {
      skip: searchParams.get('id') == null,
    },
  )

  const [
    updateTextTemplate,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess },
  ] = useUpdateTextTemplateMutation()

  const [
    addTextTemplate,
    {
      isLoading: isCreateTemplate,
      error: createError,
      isSuccess: isCreateSuccess,
    },
  ] = useAddTextTemplateMutation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      text_template: '',
      ...template,
    } as UseQueryHookResultData<typeof useGetTextTemplateQuery>,
    validationSchema: Yup.object().shape({
      created_at: Yup.string().nullable(),
      updated_at: Yup.string().nullable(),
      name: Yup.string(),
      text_template: Yup.string(),
    }),
    onSubmit: (values) => {
      searchParams.get('id')
        ? updateTextTemplate(values)
        : addTextTemplate(values)
    },
  })

  const title = searchParams.get('id')
    ? `${t('Templates claim')}: ${t('Edit')}`
    : `${t('Templates claim')}: ${t('Create')}`

  if (isError) {
    return <ErrorPage title={title} error={error} backLink={undefined} />
  }

  if (isFetching) {
    return <LoadingPage title={title} backLink={undefined} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            createLink={undefined}
            backLink={'/claim-templates'}
            editLink={undefined}
            entity={template}
            buttons={undefined}
            disableBorder={undefined}
          />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md="5">
                <FormGroup>
                  <Label htmlFor="name">{t('name')}</Label>
                  <Input
                    name="name"
                    type="textarea"
                    id="name"
                    className="form-control form-control-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name || ''}
                    disabled={isFetching}
                    onInput={autoResizeInput}
                    style={{ height: '25px' }}
                    invalid={formik.touched.name && !!formik.errors.name}
                  />
                </FormGroup>
              </Col>

              <Col md="7">
                <FormGroup>
                  <Label htmlFor="text_template">{t('text_template')}</Label>
                  <Input
                    name="text_template"
                    type="textarea"
                    id="text_template"
                    className="form-control form-control-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.text_template || ''}
                    disabled={isFetching}
                    onInput={autoResizeInput}
                    style={{ height: '25px' }}
                    invalid={
                      formik.touched.text_template &&
                      !!formik.errors.text_template
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            {(createError || updateError) && (
              <Alert color="warning">
                <strong>
                  {' '}
                  {JSON.stringify(createError) ||
                    JSON.stringify(updateError)}{' '}
                </strong>
              </Alert>
            )}

            {(isUpdateSuccess || isCreateSuccess) && (
              <Alert color="success">
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
            <Button
              className="text-nowrap"
              color="primary"
              style={{ backgroundColor: '#405189' }}
              type="submit"
              disabled={isFetching || isUpdating || isCreateTemplate}
            >
              {searchParams.get('id') ? t('Update') : t('Create')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
