import { createSelector } from 'reselect'
import { RootState } from 'src/store'
import { api, omitBlankEntries } from './api'
import {
  PersistentWebSocket,
  PersistentWebSocketProps,
} from './PersistentWebSocket'

type MessageItem = {
  id: number
  created_at: string
  updated_at: string | null
  chat_id: number
  author_type: 'client' | 'user'
  author_id: string
  content_type: 'text' | 'image'
  content: string
  is_new: boolean
}

type MessageResponse =
  | {
      type: 'CONNECT'
      payload: MessageItem[]
    }
  | {
      type: 'MESSAGE'
      payload: MessageItem
    }
  | {
      type: 'READ'
      payload: Pick<MessageItem, 'id'>
    }

type MessageRequest =
  | {
      type: 'CONNECT'
      payload: {}
    }
  | {
      type: 'MESSAGE'
      payload: {
        content_type: MessageItem['content_type']
        content: MessageItem['content']
      }
    }
  | {
      type: 'READ'
      payload: {
        id: MessageItem['id']
      }
    }

type Chat = {
  id: number
  created_at: string
  updated_at: string
  profile_id: string
  session_id: string
  assignee_id: string
  queued_at: string
  assigned_at: string
  claim_topic_id: number
  claim_subtopic_id: number
  status: string
  company_name: string
  channel: string
  client_name: string
  client_phone: string
}

export type ChatTopic = {
  id: number
  topic_name: string
  parent_topic_id: number
  priority: number
  roles: string
  chat_visible: boolean
}

type ChatTemplate = {
  id: number
  created_at: string
  updated_at: string
  name: string
  text_template: string
}

type ChatGlobalSettings = {
  id: number
  user_id: string
  is_global: boolean
  idle_message: string
  idle_interval: number
  close_interval: number
  max_chats_limit: number
}

type ChatUserSettings = {
  id: number
  user_id: string
  is_global: boolean
  idle_message: string
  idle_interval: number
  close_interval: number
  max_chats_limit: number
}

function createSocketsFactory(domain: string) {
  const sockets = new Map<string, PersistentWebSocket>()

  return function ({
    path,
    ...props
  }: Omit<PersistentWebSocketProps, 'url'> & {
    path: string | number
  }): PersistentWebSocket {
    if (!sockets.has(path.toString())) {
      sockets.set(
        path.toString(),
        new PersistentWebSocket({
          url: domain + path,
          enableReconnect: true,
          ...props,
        }),
      )
    }

    return sockets.get(path.toString())!
  }
}

/*
 * Get singleton instance of websocket
 */
const getSocket = createSocketsFactory(`${process.env.REACT_APP_CHAT_SOCKET}`)

const messagesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyChats: builder.query<Chat[], void>({
      query: () => ({
        url: 'crm/v1/chats/my',
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Chats', id }) as const),
        { type: 'Chats' as const, id: 'LIST' },
      ],
      // connect to chats as soon as they are loaded automatically
      async onCacheEntryAdded(
        _,
        { cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        try {
          const chats = (await cacheDataLoaded).data
          chats.forEach((chat) =>
            dispatch(
              messagesApi.endpoints.getMessages.initiate({
                chatId: chat.id.toString(),
              }),
            ),
          )
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        await cacheEntryRemoved
      },
    }),
    getChat: builder.query<Chat, string>({
      query: (id) => ({
        url: `crm/v1/chats/${id}`,
      }),
      providesTags: (_result, _err, id) => [{ type: 'Chats', id }],
    }),
    updateChat: builder.mutation<void, Chat>({
      query: ({ id, ...body }) => ({
        url: `crm/v1/chats/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, _error, body) => [
        { type: 'Chats', id: body.id },
      ],
    }),

    getChatTopics: builder.query<ChatTopic[], void>({
      query: () => ({
        url: 'crm/v1/chats/topics',
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'ChatTopics', id }) as const),
        { type: 'ChatTopics' as const, id: 'LIST' },
      ],
    }),
    setChatTopicVisible: builder.mutation<void, { topic_id: number }>({
      query: (body) => ({
        url: `crm/v1/chats/topics`,
        method: 'POST',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: () => [{ type: 'ChatTopics', id: 'LIST' }],
    }),
    setChatTopicHidden: builder.mutation<void, { topic_id: number }>({
      query: (body) => ({
        url: `crm/v1/chats/topics`,
        method: 'DELETE',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: () => [{ type: 'ChatTopics', id: 'LIST' }],
    }),

    // chats/templates returns { id: number ...} whereas getting a parameter from URL query (useSearchParams, useParams -> string)
    // and then passing it to useGetChatTemplate will result in an unexpected behaviour because
    // RTKQ uses strict comparison (===) and id: 1 === id: '1' is false but should be true
    // either you have to convert URL parameter to a number every time or convert all to a astring
    getChatTemplates: builder.query<ChatTemplate[], void>({
      query: () => ({
        url: 'crm/v1/chats/templates',
      }),
      providesTags: (result = []) => [
        ...result.map(
          ({ id }) => ({ type: 'ChatTemplates', id: id.toString() }) as const,
        ),
        { type: 'ChatTemplates' as const, id: 'LIST' },
      ],
    }),
    getChatTemplate: builder.query<ChatTemplate, string | number>({
      query: (id) => `crm/v1/chats/templates/${id}`,
      providesTags: (result) => [
        { type: 'ChatTemplates', id: result?.id.toString() },
      ],
    }),
    updateChatTemplate: builder.mutation<
      void,
      Omit<Partial<ChatTemplate>, 'id'> & { id: string | number }
    >({
      query: ({ id, ...body }) => ({
        url: `crm/v1/chats/templates/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: (_result, _err, { id }) => [
        { type: 'ChatTemplates', id: id?.toString() },
      ],
    }),
    addChatTemplate: builder.mutation<void, Partial<ChatTemplate>>({
      query: (body) => ({
        url: `crm/v1/chats/templates`,
        method: 'POST',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: () => [{ type: 'ChatTemplates', id: 'LIST' }],
    }),

    getChatUserSettings: builder.query<ChatUserSettings, string>({
      query: (user_id) => `crm/v1/chats/settings/${user_id}`,
      providesTags: (_result, _error, user_id) => [
        { type: 'ChatUserSettings', id: user_id },
      ],
    }),
    updateChatUserSettings: builder.mutation<void, Partial<ChatUserSettings>>({
      query: ({ user_id, ...body }) => ({
        url: `crm/v1/chats/settings/${user_id}`,
        method: 'PUT',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: (_result, _error, { user_id }) => [
        { type: 'ChatUserSettings', id: user_id },
      ],
    }),

    getChatGlobalSettings: builder.query<ChatGlobalSettings, void>({
      query: () => `crm/v1/chats/settings`,
      providesTags: () => ['ChatGlobalSettings'],
    }),
    // NOTE: onCacheEntryAdded(){websocket(chatId).close?}
    updateChatGlobalSettings: builder.mutation<
      void,
      Partial<ChatGlobalSettings>
    >({
      query: (body) => ({
        url: `crm/v1/chats/settings`,
        method: 'PUT',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: () => ['ChatGlobalSettings'],
    }),

    readMessage: builder.mutation<
      null,
      Pick<MessageItem, 'id'> & { chat_id: number | string }
    >({
      queryFn: async ({ chat_id, id }) => {
        getSocket({ path: `${chat_id}` })?.send(
          JSON.stringify({
            type: 'READ',
            payload: { id },
          }),
        )
        return { data: null }
      },
      async onQueryStarted({ id, chat_id }, { dispatch }) {
        dispatch(
          messagesApi.util.updateQueryData(
            'getMessages',
            { chatId: `${chat_id}` },
            (draft) => {
              draft.forEach((message, index) => {
                if (message.id === id) draft[index].is_new = false
              })
            },
          ),
        )
      },
    }),
    sendMessage: builder.mutation<
      null,
      MessageRequest['payload'] & { chat_id: number | string }
    >({
      queryFn: async ({ chat_id, ...payload }) => {
        getSocket({ path: `${chat_id}` })?.send(
          JSON.stringify({
            type: 'MESSAGE',
            payload,
          }),
        )
        return { data: null }
      },
    }),
    getMessages: builder.query<
      MessageItem[],
      {
        chatId: string
      }
    >({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(
        { chatId },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            const data: MessageResponse = JSON.parse(event.data)

            switch (data.type) {
              case 'MESSAGE':
                updateCachedData((draft) => {
                  draft.push(data.payload)
                })
                break

              case 'CONNECT':
                updateCachedData((draft) =>
                  draft.concat(
                    // payload not used anywhere below so it can safely be mutated with `sort`
                    data.payload.sort((a: MessageItem, b: MessageItem) => {
                      const dateA = new Date(a.created_at)
                      const dateB = new Date(b.created_at)

                      if (dateA < dateB) {
                        return -1
                      }
                      if (dateA > dateB) {
                        return 1
                      }
                      return 0
                    }),
                  ),
                )
                break

              case 'READ':
                updateCachedData((draft) => {
                  draft.forEach((message, index) => {
                    if (message.id === data.payload.id)
                      draft[index].is_new = false
                  })
                })
                break
            }
          }

          const ws = getSocket({ path: chatId })

          ws.addEventListener('message', listener)

          // TODO: replace with promise
          setTimeout(() => {
            ws.send(
              JSON.stringify({
                type: 'CONNECT',
                payload: {},
              }),
            )
          }, 2000)
          // create a websocket connection when the cache subscription starts
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        // ws.close()
      },
    }),
  }),
})

export const {
  useGetMessagesQuery,
  useGetChatQuery,
  useGetMyChatsQuery,
  useUpdateChatMutation,
  useSendMessageMutation,
  useGetChatTopicsQuery,
  useSetChatTopicVisibleMutation,
  useSetChatTopicHiddenMutation,
  useGetChatTemplatesQuery,
  useGetChatTemplateQuery,
  useUpdateChatTemplateMutation,
  useAddChatTemplateMutation,
  useGetChatGlobalSettingsQuery,
  useUpdateChatGlobalSettingsMutation,
  useGetChatUserSettingsQuery,
  useUpdateChatUserSettingsMutation,
  useReadMessageMutation,
} = messagesApi

export const selectUnreadMessages = createSelector(
  (state: RootState) => state,
  (_: RootState) => messagesApi.endpoints.getMyChats.select,
  (_: RootState) => messagesApi.endpoints.getMessages.select,
  (state, selectMyChats, selectMessages) => {
    const chats = selectMyChats()(state).data || []

    return chats.reduce((unreadMessages, chat) => {
      const messages =
        selectMessages({ chatId: chat.id.toString() })(state).data || []

      return [
        ...unreadMessages,
        ...messages.filter(
          (message) => message.is_new && message.author_type !== 'user',
        ),
      ]
    }, [] as MessageItem[])
  },
)

export const selectChatsWithMessagePreview = createSelector(
  (state: RootState) => state,
  (_: RootState) => messagesApi.endpoints.getMyChats.select,
  (_: RootState) => messagesApi.endpoints.getMessages.select,
  (state, selectMyChats, selectMessages) => {
    const chats = selectMyChats()(state).data || []

    return chats.map((chat) => {
      const messages =
        selectMessages({ chatId: chat.id.toString() })(state).data || []

      const unreadCount = messages.reduce(
        (count, message) =>
          message.is_new && message.author_type !== 'user' ? count + 1 : count,
        0,
      )

      return {
        ...chat,
        messagePreview: {
          ...messages[messages.length - 1],
          unreadCount,
        },
      }
    })
  },
)
