import React, { useEffect, useState } from 'react'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { Card, CardBody, Input, Label, Button } from 'reactstrap'
import MetaBar from 'src/Components/Common/MetaBar'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from 'src/store/users/action'
import LoadingPage from '../LoadingPage'
import API from 'src/client'
import ErrorPage from '../ErrorPage'
import Client from 'src/client'

export const UserView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [userId, setUserId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [activePermissions, setActivePermissions] = useState([])
  const [paramError, setParamError] = useState(null)
  const title = t('View user')
  document.title = title

  const params = new URLSearchParams(window.location.search)

  const {
    user,
    listLoading,
    listError,
    list: allPermissions,
  } = useSelector((state) => ({
    user:
      state.users.list && state.users.list.length ? state.users.list[0] : null,
    listLoading: state.users.listLoading,
    listError: state.users.listError,
    list: state.entities[Client.ENTITY_TYPE.PERMISSIONS].list,
  }))
  const activeRoles = user?.activeRoles ? Object.keys(user.activeRoles) : []
  console.log('allPermissions =>', allPermissions)
  console.log('activePermissions =>', activePermissions)
  console.log('user.activeRoles =>', user?.activeRoles)
  console.log('activeRoles =>', activeRoles)

  useEffect(() => {
    const id = params.get('id')
    setUserId(id || null)
    if (id) dispatch(getUsers(id))
  }, [])

  useEffect(() => {
    if (!user) return
    setPageReady(true)
    if (user.activeRoles) {
      const set = new Set()
      activeRoles.forEach((item) => {
        user.activeRoles[item].forEach((permission) => set.add(permission))
      })
      setActivePermissions([...set])
    }
  }, [user])

  if (!pageReady || listLoading) {
    return <LoadingPage backLink={'/users'} title={title} />
  }

  if (listError) {
    return <ErrorPage backLink={'/users'} title={title} error={[listError]} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={t('View user') + ` ${userId}`} />
      <Card>
        <CardBody>
          <MetaBar backLabel={'back'} backLink={`/users`} />
          <div
            className=""
            style={{
              display: 'flex',
              paddingTop: '5px',
              width: 'auto',
              gap: 60,
            }}
          >
            <div style={{}}>
              <p
                style={{
                  fontSize: '16px',
                  marginBottom: '15px',
                  fontWeight: 'bold',
                }}
              >
                {t('Roles')}
              </p>
              {user &&
                user.allRoles.length > 0 &&
                user.allRoles.map((permission) => (
                  <div
                    style={{
                      verticalAlign: 'baseline',
                      display: 'block',
                      lineHeight: '21px',
                      fontSize: '14px',
                      marginBottom: '10px',
                    }}
                    key={permission.name}
                    className="form-check-label"
                  >
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      checked={activeRoles.find(
                        (item) => permission.name === item,
                      )}
                      id={permission.name}
                      style={{
                        width: '21px',
                        height: '21px',
                        marginRight: '15px',
                        cursor: 'pointer',
                      }}
                      onChange={async () => {
                        const index = activeRoles.indexOf(permission.name)
                        try {
                          if (index > -1) {
                            setPageReady(false)
                            await API.removeUserFromRole(permission.name, {
                              username: userId,
                            })
                            const newPermissions = JSON.parse(
                              JSON.stringify(activeRoles),
                            )
                            newPermissions.splice(index, 1)
                            dispatch(getUsers(userId))
                            setPageReady(true)
                          } else {
                            setPageReady(false)
                            await API.addUserToRole(permission.name, {
                              username: userId,
                            })
                            dispatch(getUsers(userId))
                            setPageReady(true)
                          }
                        } catch (e) {
                          setPageReady(true)
                          setParamError(e)
                        }
                      }}
                    />
                    <span>{permission.name}</span>
                  </div>
                ))}
            </div>
            <div style={{ width: 'auto' }}>
              <p
                style={{
                  fontSize: '16px',
                  marginBottom: '15px',
                  fontWeight: 'bold',
                }}
              >
                {t('Permissions')}
              </p>
              {activePermissions?.map((permission) => (
                <p key={permission}>{permission}</p>
              ))}
              <Button
                color="success"
                onClick={async () => {
                  setParamError(false)
                  Client.addUserPermissions(userId, activePermissions)
                    .then((res) => {
                      console.log('addUserPermissions res =>', res)
                    })
                    .catch((e) => {
                      setParamError(e.toString())
                    })
                }}
              >
                {t('Save')}
              </Button>
              {paramError && <div className="text-danger">{paramError}</div>}
            </div>
            <div>
              {Object.keys(allPermissions).map((permission) => (
                <Label
                  style={{
                    verticalAlign: 'baseline',
                    display: 'block',
                    lineHeight: '21px',
                    fontSize: '14px',
                    marginBottom: '10px',
                  }}
                  key={permission}
                  className="form-check-label"
                >
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={activePermissions.includes(
                      permission.replaceAll('_', ' ').toUpperCase(),
                    )}
                    id={permission}
                    style={{
                      width: '21px',
                      height: '21px',
                      marginRight: '15px',
                      cursor: 'pointer',
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        //add
                        const newPermissions = [...activePermissions]
                        newPermissions.push(permission.replaceAll('_', ' '))
                        setActivePermissions(newPermissions)
                      } else {
                        //remove
                        const newPermissions = activePermissions.filter(
                          (p) => p !== permission.replaceAll('_', ' '),
                        )
                        setActivePermissions(newPermissions)
                      }
                    }}
                  />
                  <span>{permission}</span>
                </Label>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
