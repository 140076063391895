import React from "react";
import {useTranslation} from "react-i18next";

import {
  Row,
  Col,
  Container,
} from "reactstrap";

const EntityContent = ({entity, style}) => {
  const {t, i18n} = useTranslation();

  const isValid = entity &&
    entity.requirement &&
    entity.result &&
    entity.pass;

  if (!isValid) return (
    <div style={style}>
      <dl className="row mb-0">
        <small>{t('No_decisions')}</small>
      </dl>
    </div>
  );

  return (
    <div style={style}>
      <dl className="row mb-0">
      {entity.requirement.map((req, rank) =>
        <React.Fragment key={`${req}-${rank}`}>
          <dd className="col-12 mb-0">{`${req}`}</dd>
          <dd className={`${entity.pass[rank] ? "text-success" : "text-danger"} col-12`}>
            {`${entity.result[rank]}`}
          </dd>
        </React.Fragment>
      )}
      </dl>
    </div>
  );
}

export default EntityContent;
