import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody, Input, Table } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRoles } from '../../../store/roles/action'
import UiContent from '../../../Components/Common/UiContent'
import API from '../../../client'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import { useNavigate } from 'react-router-dom'

export const Roles = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [newRoleName, setNewRoleName] = useState('')
  const dispatch = useDispatch()
  const [paramError, setParamError] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const title = t('Roles')
  document.title = title
  const { list, listLoading, listError } = useSelector((state) => ({
    list: state.roles.list,
    listLoading: state.roles.listLoading,
    listError: state.roles.listError,
  }))

  useEffect(() => {
    dispatch(getRoles())
    setPageReady(true)
  }, [dispatch])

  const minimizeTextStyle = {
    textOverflow: 'ellipsis',
    maxWidth: 200,
    overflow: 'hidden',
  }

  if (!pageReady || listLoading) {
    return <LoadingPage backLink={'/products'} title={title} />
  }

  if (paramError || listError) {
    return (
      <ErrorPage
        backLink={'/products'}
        title={title}
        error={[paramError, listError]}
      />
    )
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <div style={{ display: 'flex' }}>
              <Input
                style={{ width: '400px', marginRight: '15px' }}
                value={newRoleName}
                onChange={(evv) => setNewRoleName(evv.target.value)}
              />
              <Button
                color="success"
                onClick={async () => {
                  try {
                    await API.createRole(newRoleName)
                    await API.addRolePermission(newRoleName, [])
                    navigate(`/role-view?id=${newRoleName}`)
                  } catch (e) {
                    setParamError(e)
                  }
                }}
              >
                {t('Create')}
              </Button>
            </div>
            <Table
              hover
              className={'table-sm align-middle table-nowrap mb-0'}
              style={{ width: '500px' }}
            >
              <thead>
                <tr>
                  <th scope="col" className="ps-3">
                    {t('Name')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.map((role) => (
                  <tr key={role.name}>
                    <td className="ps-3" style={{ ...minimizeTextStyle }}>
                      <Link to={`/role-view?id=${role.name}`}>{role.name}</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
