import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import Clients from 'src/client'
import Select from 'src/Components/Common/Select'

export const MassTags = () => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [uploadStatus, setUploadStatus] = useState(null)
  const [type, setType] = useState('')
  const title = t('Add Mass Tags')
  document.title = title

  const option = [
    {
      name: '',
      value: '',
    },
    {
      name: 'loan',
      value: 'loan',
    },
    {
      name: 'client',
      value: 'client',
    },
  ]

  const onUpload = () => {
    const fileInput = document.getElementById('upload-file')
    fileInput.click()
  }

  const buttonUpload = (
    <Button
      disabled={type.length < 1}
      className="btn-label"
      color="primary"
      style={{ backgroundColor: '#405189' }}
      onClick={onUpload}
    >
      <i className="ri-add-line label-icon align-middle fs-16 me-2"></i>
      {t('Загрузить документ')}
    </Button>
  )

  const uploadFile = (file) => {
    setLoadingUpload(true)
    setUploadStatus(null)

    if (file.size / (1024 * 1024) > 10) {
      setUploadStatus('Большой размер файла (>10MB)')
      return
    }

    Clients.addMassTags(file, type)
      .then((res) => {
        console.log('uploadDocument', res)
        if (res.status === 200) {
          setUploadStatus(true)
        } else {
          setUploadStatus(`ошибка загрузки: ${res.status}`)
        }
      })
      .catch((err) => {
        setUploadStatus(err)
      })
      .finally(() => {
        setLoadingUpload(false)
      })
  }

  const onChangeFile = () => {
    const fileInput = document.getElementById('upload-file')
    if (fileInput?.files?.length) {
      const file = fileInput.files[0]
      uploadFile(file)
    }
  }

  const invisibleInputFile = (
    <input
      id="upload-file"
      type="file"
      onChange={onChangeFile}
      className="d-none"
      accept=".csv"
    />
  )

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
              <Select value={type} options={option} onChange={setType} />
              {buttonUpload}
              {invisibleInputFile}
              {uploadStatus && (
                <div className="mt-2">
                  <small
                    className={
                      uploadStatus === true
                        ? 'text-bold text-success'
                        : 'text-bold text-danger'
                    }
                  >
                    {uploadStatus === true
                      ? `Документ успешно загружен.`
                      : uploadStatus.toString()}
                  </small>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
