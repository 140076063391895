import React from 'react'
import { Alert, Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import UiContent from 'src/Components/Common/UiContent'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'

/**
 * @param {{title: any, backLink?: string, error?: any }}
 */
const ErrorPage = ({ backLink, title, error }) => {
  const { t } = useTranslation()

  const bread = <BreadCrumb title={t(title)} />
  const metaBar = backLink ? <MetaBar backLink={backLink} /> : null

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Alert color="danger" className="alert-top-border">
              <i className="ri-airplay-line me-3 align-middle fs-16 text-danger" />
              <strong>{`${t('smth_wrong')}: `}</strong>
              {!!error &&
                `${error.status} ${JSON.stringify(error.data.detail)}`}
            </Alert>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default ErrorPage
