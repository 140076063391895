import { useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Client from 'src/client'
import { useTranslation } from 'react-i18next'
import {
  useGetUserInfoQuery,
  useGetUserStatusesQuery,
  useSetUserStatusMutation,
} from 'src/services/auth'

const ProfileDropdown = () => {
  const { t } = useTranslation()

  const { data: userInfo } = useGetUserInfoQuery()

  const { data: userStatuses } = useGetUserStatusesQuery()

  const [setUserStatus] = useSetUserStatusMutation()

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false)

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown)
  }

  return (
    <Dropdown
      isOpen={isProfileDropdown}
      toggle={toggleProfileDropdown}
      className="ms-sm-3 header-item topbar-user"
    >
      <DropdownToggle tag="button" type="button" className="btn">
        <span className="d-flex align-items-center">
          <div>
            <i className="ri-user-3-line fs-20" />
          </div>

          <span className="text-start ms-xl-2">
            <span className="d-inline-block ms-1 fw-medium user-name-text">
              {userInfo?.surname || userInfo?.name || userInfo?.midname
                ? `${userInfo.surname} ${userInfo.name} ${userInfo.midname}`
                : userInfo?.username}
            </span>
            <span className="d-block ms-1 fs-12 text-muted user-name-sub-text">
              {userInfo?.status}
            </span>
          </span>
        </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        {/*<h6 className="dropdown-header">Welcome {userName}!</h6>*/}
        {/*<DropdownItem href="/pages-profile"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>*/}
        {/*    <span className="align-middle">Profile</span></DropdownItem>*/}
        {/*<DropdownItem href="/apps-chat"><i*/}
        {/*    className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span*/}
        {/*        className="align-middle">Messages</span></DropdownItem>*/}
        {/*<DropdownItem href="/apps-tasks-kanban"><i*/}
        {/*    className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span*/}
        {/*        className="align-middle">Taskboard</span></DropdownItem>*/}
        {/*<DropdownItem href="/pages-faqs"><i*/}
        {/*    className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span*/}
        {/*        className="align-middle">Help</span></DropdownItem>*/}
        {/*<div className="dropdown-divider"></div>*/}
        {/*<DropdownItem href="/pages-profile"><i*/}
        {/*    className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span*/}
        {/*        className="align-middle">Balance : <b>$5971.67</b></span></DropdownItem>*/}
        {/*<DropdownItem href="/settings"><span*/}
        {/*    className="badge bg-soft-success text-success mt-1 float-end">New</span><i*/}
        {/*        className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span*/}
        {/*            className="align-middle">Settings</span></DropdownItem>*/}
        <h6 className="dropdown-header">
          {t('logged_as')} {userInfo?.username}
        </h6>
        <DropdownItem disabled={true} href={`/pages-profile/${userInfo?.id}`}>
          <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
          <span className="align-middle">{t('Profile')}</span>
        </DropdownItem>
        <Link to="/verification-applications">
          <DropdownItem>
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1" />
            <span className="align-middle">{t('Applications')}</span>
          </DropdownItem>
        </Link>
        <DropdownItem
          disabled={true}
          href={'https://api.dev.cash-u.com/crm/v1/logout'}
        >
          <i className="mdi mdi-lock text-muted fs-16 align-middle me-1" />
          <span className="align-middle">{t('Lock Screen')}</span>
        </DropdownItem>
        <DropdownItem href={Client.logout()}>
          <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />
          <span className="align-middle">{t('Logout')}</span>
        </DropdownItem>
        <div className="dropdown-divider"></div>
        <h6 className="dropdown-header">{t('Change status')}</h6>
        {userStatuses
          ?.filter((status) => status !== userInfo?.status)
          .map((status) => (
            <DropdownItem key={status} onClick={() => setUserStatus(status)}>
              <span className="align-middle">{status}</span>
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default ProfileDropdown
