import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import List from './List'
import Client from '../../../client'

import { useGetLoanSignedDocumentsQuery } from 'src/services/loans'

const fieldsDescription = {
  type: {},
  signed_at: { isDateTime: true },
}

const EntityContent = ({ entityId, entity, style, visible }) => {
  const { t } = useTranslation()

  const download = (doc) => {
    Client.getSignedDocuments(doc.doc_id)
      .then((file) => file.blob())
      .then((blob) => window.URL.createObjectURL(blob))
      .then((url) => {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `${doc.doc_name}.html`
        a.click()
        document.body.removeChild(a)
      })
      .catch((err) => console.log(err))
  }

  const { data } = useGetLoanSignedDocumentsQuery(entityId, {
    skip: !visible || entity,
  })

  const signedDocuments = useMemo(
    () => (entity ? entity : data),
    [data, entity],
  )

  if (!signedDocuments || !signedDocuments.length)
    return (
      <div style={style}>
        <dl className="row mb-0">
          <small>{t('No_signed_documents')}</small>
        </dl>
      </div>
    )

  return (
    <List
      hover
      list={signedDocuments.map((doc) => ({
        ...doc,
        onPress: () => download(doc),
      }))}
      fieldsDescription={fieldsDescription}
      style={style}
      onRowPress={download}
    />
  )
}

export default EntityContent
