import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import {
  Row,
  Col,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
} from 'reactstrap'
import { formatDateTime } from 'src/utils'
import { Link, useSearchParams } from 'react-router-dom'

import { useGetClaimManagerQuery, useGetClaimQuery } from 'src/services/claims'
import { useGetClientsQuery } from 'src/services/clients'
import { useGetUserInfoQuery } from 'src/services/auth'

export const ClaimView: FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [clientName, setClientName] = useState('')
  const [isUserName, setIsUserName] = useState('')
  const [isUserWork, setIsUserWork] = useState(true)
  const [id, setId] = useState<string>()

  const { data: userInfo } = useGetUserInfoQuery()

  const {
    data: claim,
    isError,
    error,
  } = useGetClaimQuery(
    {
      id: id!,
    },
    {
      skip: id == null,
    },
  )

  const { data: claimManager } = useGetClaimManagerQuery()

  const { data: clients } = useGetClientsQuery({})

  useEffect(() => {
    const maybeId = searchParams.get('id')
    if (maybeId) setId(maybeId)
  }, [searchParams])

  useEffect(() => {
    clients?.forEach((client) => {
      if (client.id === claim?.client_id) {
        setClientName(
          `${client.last_name} ${client.first_name} ${client.middle_name}`,
        )
      }
    })

    claimManager?.forEach((user) => {
      if (user.id === claim?.assignee_id) {
        setIsUserName(`${user.surname} ${user.name} ${user.midname}`)
      }
    })
  }, [clients, claim, claimManager])

  useEffect(() => {
    userInfo?.roles.includes('head_of_comunications')
      ? setIsUserWork(false)
      : setIsUserWork(true)
  }, [userInfo])

  const title = t('View claim')
  document.title = title

  if (isError) {
    return <ErrorPage backLink={'/products'} title={title} error={error} />
  }

  if (!claim) {
    return <LoadingPage backLink={'/products'} title={title} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            backLink={isUserWork ? '/my-claims' : '/claims-queue'}
            editLink={claim ? `/claim-edit/?id=${claim.id}` : null}
            entity={claim}
            createLink={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          {claim && (
            <ListGroup>
              <ListGroupItem>
                <Row>
                  <Col>{t('claim_number')}</Col>
                  <Col>{claim.claim_number}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('parent_claim_uuid')}</Col>
                  <Col>
                    <Link
                      to={`/main-claim-view?id=${claim.parent_claim_uuid}`}
                      className="fw-medium"
                    >
                      {claim.parent_claim_uuid}
                    </Link>
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('client_id')}</Col>
                  <Col>
                    <Link
                      to={`/client-view?id=${claim.client_id}`}
                      className="fw-medium"
                    >
                      {claim.client_id}
                    </Link>
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('fio_client')}</Col>
                  <Col>{clientName}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('created')}</Col>
                  <Col>{formatDateTime(claim.created_at)}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('updated')}</Col>
                  <Col>{formatDateTime(claim.updated_at)}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('closed_at')}</Col>
                  <Col>{formatDateTime(claim.closed_at)}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('channel_type')}</Col>
                  <Col>{claim.channel_type}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('status')}</Col>
                  <Col>{claim.status}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('result_claim')}</Col>
                  <Col>{claim.result}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('Full name of the employee')}</Col>
                  <Col>{isUserName}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('client_email')}</Col>
                  <Col>{claim.client_email}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('email_from')}</Col>
                  <Col>{claim.email_from}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('email_to')}</Col>
                  <Col>{claim.email_to}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('repeated')}</Col>
                  <Col>{claim.repeated ? t('yes') : t('no')}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('is_complaint')}</Col>
                  <Col>{claim.is_complaint ? t('yes') : t('no')}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('days_to_reply')}</Col>
                  <Col>{claim.days_to_reply}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('claim_topic_id')}</Col>
                  <Col>{claim?.claim_topic_name}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('claim_subtopic_id')}</Col>
                  <Col>{claim?.claim_subtopic_name}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('claim_topic')}</Col>
                  <Col>{claim.claim_topic}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('claim_body')}</Col>
                  <Col>{claim.claim_body}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('Comment')}</Col>
                  <Col>{claim.comment}</Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          )}
          <Link to={`/claim-response?id=${claim.id}`} className="fw-medium">
            <Button
              className="mt-3"
              color="primary"
              style={{ backgroundColor: '#405189' }}
            >
              {t('write an answer')}
            </Button>
          </Link>
        </CardBody>
      </Card>
    </div>
  )
}
