import { Layout } from './Layouts'
import type { RouteObject } from 'react-router-dom'
import { Agencies } from 'src/pages/Admin/Agencies'
import { AgencyEdit } from 'src/pages/Admin/Agencies/Edit'
import { AgencyView } from 'src/pages/Admin/Agencies/View'
import { ApplicationView } from 'src/pages/Admin/Applications/View'
import { Claims } from 'src/pages/Admin/Claims'
import { ClaimEdit } from 'src/pages/Admin/Claims/Edit'
import { EditMainClaim } from './pages/Admin/Claims/EditMainClaim'
import { ViewMainClaim } from './pages/Admin/Claims/ViewMainClaim'
import { MyClaims } from './pages/Admin/Claims/MyClaims'
import { ClaimResponse } from './pages/Admin/Claims/ClaimResponse'
import { ClaimView } from 'src/pages/Admin/Claims/View'
import { TemplatesClaim } from './pages/Admin/Claims/TemplatesClaim'
import { TemplatesClaimEdit } from './pages/Admin/Claims/TemplatesClaim/Edit'
import { TemplatesClaimView } from './pages/Admin/Claims/TemplatesClaim/View'
import { ReportClaim } from './pages/Admin/Claims/ReportClaim'
import { ClientEdit } from 'src/pages/Admin/Clients/Edit'
import { ClientView } from 'src/pages/Admin/Clients/View'
import { Collectors } from 'src/pages/Admin/Collectors'
import { CollectorEdit } from 'src/pages/Admin/Collectors/Edit'
import { Debts } from 'src/pages/Admin/Debts'
import { DebtsQueue } from 'src/pages/Admin/DebtsQueue'
import { DebtsSell } from 'src/pages/Admin/DebtsSell'
import { DialogueView } from 'src/pages/Admin/Dialogues/View'
import { DocumentTypes } from 'src/pages/Admin/DocumentTypes'
import { DocumentTypeEdit } from 'src/pages/Admin/DocumentTypes/Edit'
import { DocumentTypeView } from 'src/pages/Admin/DocumentTypes/View'
import { ExternalServicesSettingsEdit } from 'src/pages/Admin/ExternalServicesSettings/Edit'
import { ExternalServicesSettingsView } from 'src/pages/Admin/ExternalServicesSettings/View'
import { Groups } from 'src/pages/Admin/Groups'
import { GroupEdit } from 'src/pages/Admin/Groups/Edit'
import { LoanEdit } from 'src/pages/Admin/Loans/Edit'
import { LoanView } from 'src/pages/Admin/Loans/View'
import { NotificationsMappingTemplates } from 'src/pages/Admin/NotificationsMappingTemplates'
import { NotificationMappingTemplateEdit } from 'src/pages/Admin/NotificationsMappingTemplates/Edit'
import { NotificationMappingTemplateView } from 'src/pages/Admin/NotificationsMappingTemplates/View'
import { NotificationsSenders } from 'src/pages/Admin/NotificationsSenders'
import { NotificationsSenderEdit } from 'src/pages/Admin/NotificationsSenders/Edit'
import { NotificationsSenderView } from 'src/pages/Admin/NotificationsSenders/View'
import { NotificationsTemplates } from 'src/pages/Admin/NotificationsTemplates'
import { NotificationTemplateEdit } from 'src/pages/Admin/NotificationsTemplates/Edit'
import { NotificationTemplateView } from 'src/pages/Admin/NotificationsTemplates/View'
import { Payments } from 'src/pages/Admin/Payments'
import { PaymentEdit } from 'src/pages/Admin/Payments/Edit'
import { PaymentView } from 'src/pages/Admin/Payments/View'
import { ProductExtensionEdit } from 'src/pages/Admin/ProductExtensions/Edit'
import { ProductExtensionView } from 'src/pages/Admin/ProductExtensions/View'
import { ProductEdit } from 'src/pages/Admin/Products/Edit'
import { ProductView } from 'src/pages/Admin/Products/View'
import { ProfileView } from 'src/pages/Admin/Profiles/View'
import { Promocodes } from 'src/pages/Admin/Promocodes'
import { PromocodeEdit } from 'src/pages/Admin/Promocodes/Edit'
import { PromocodeView } from 'src/pages/Admin/Promocodes/View'
import { Queues } from 'src/pages/Admin/Queues'
import { QueueEdit } from 'src/pages/Admin/Queues/Edit'
import { QueueView } from 'src/pages/Admin/Queues/View'
import { RecPayments } from 'src/pages/Admin/RecPayments'
import { RoleView } from 'src/pages/Admin/Roles/View'
import { RosFinMonitoring } from 'src/pages/Admin/RosFinMonitoring'
import { Segments } from 'src/pages/Admin/Segments'
import { SegmentEdit } from 'src/pages/Admin/Segments/Edit'
import { SegmentView } from 'src/pages/Admin/Segments/View'
import { Settings } from 'src/pages/Admin/Settings'
import { StrategyEdit } from 'src/pages/Admin/Strategy/Edit'
import { StrategyView } from 'src/pages/Admin/Strategy/View'
import { Supervisor } from 'src/pages/Admin/Supervisor'
import { Tags } from 'src/pages/Admin/Tags'
import { MassTags } from 'src/pages/Admin/Tags/MassTags'
import { Templates } from 'src/pages/Admin/Templates'
import { TemplateEdit } from 'src/pages/Admin/Templates/Edit'
import { TemplateView } from 'src/pages/Admin/Templates/View'
import { UserEdit } from 'src/pages/Admin/Users/Edit'
import { UserView } from 'src/pages/Admin/Users/View'
import { VerificationView } from 'src/pages/Admin/Verification/View'
import { Products } from 'src/pages/Admin/Products'
import { Clients } from 'src/pages/Admin/Clients'
import { Applications } from 'src/pages/Admin/Applications'
import { Loans } from 'src/pages/Admin/Loans'
import { Profiles } from 'src/pages/Admin/Profiles'
import { Verifications } from 'src/pages/Admin/Verification'
import { ExternalServicesSettings } from 'src/pages/Admin/ExternalServicesSettings'
import { ProductExtensions } from 'src/pages/Admin/ProductExtensions'
import { Roles } from 'src/pages/Admin/Roles'
import { Users } from 'src/pages/Admin/Users'
import { Strategies } from 'src/pages/Admin/Strategy'
import { Dialogues } from 'src/pages/Admin/Dialogues'
import { Holidays } from 'src/pages/Admin/Loans/Holidays'
import { Promotions } from './pages/Admin/Promotions'
import { PromotionEdit } from './pages/Admin/Promotions/Edit'
import { PromotionView } from './pages/Admin/Promotions/View'
import { Chats } from './pages/Admin/Chats'
import { ChatView } from './pages/Admin/Chats/View'
// import { ChatEdit } from './pages/Admin/Chats/Edit'
import { Homepage } from './pages/Admin/Homepage'
import { BkiSent } from './pages/Admin/BkiSentFile'

import { ChatsTopics } from './pages/Admin/Chats/Topics'
import { ChatsTemplates } from './pages/Admin/Chats/Templates'
import { ChatTemplateEdit } from './pages/Admin/Chats/Templates/Edit'
import { ChatTemplateView } from './pages/Admin/Chats/Templates/View'
import { ChatGlobalSettings } from './pages/Admin/Chats/GlobalSettings'
import { ChatUserSettings } from './pages/Admin/Chats/UserSettings'
import { ChatUserSettingsEdit } from './pages/Admin/Chats/UserSettings/Edit'
import { EditCompany } from './pages/Admin/Companies/EditCompany'
import { Companies } from './pages/Admin/Companies'
import { ViewCompany } from './pages/Admin/Companies/ViewCompany'

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        element: <Homepage />,
        index: true,
      },
      {
        path: '/products',
        element: <Products />,
      },
      {
        path: '/clients',
        element: <Clients />,
      },
      {
        path: '/client-edit',
        element: <ClientEdit />,
      },
      {
        path: '/client-view',
        element: <ClientView />,
      },
      {
        path: '/add-tags',
        element: <MassTags />,
      },
      {
        path: '/applications',
        element: <Applications />,
      },
      {
        path: '/application-view',
        element: <ApplicationView />,
      },
      {
        path: '/loans',
        element: <Loans />,
      },
      {
        path: '/loan-view',
        element: <LoanView />,
      },
      {
        path: '/loan-edit',
        element: <LoanEdit />,
      },
      {
        path: '/loan-holidays',
        element: <Holidays />,
      },
      {
        path: '/claims-queue',
        element: <Claims />,
      },
      {
        path: '/claim-view',
        element: <ClaimView />,
      },
      {
        path: 'main-claim-edit',
        element: <EditMainClaim />,
      },
      {
        path: 'main-claim-view',
        element: <ViewMainClaim />,
      },
      {
        path: '/claim-edit',
        element: <ClaimEdit />,
      },
      {
        path: '/my-claims',
        element: <MyClaims />,
      },
      {
        path: '/claim-response',
        element: <ClaimResponse />,
      },
      {
        path: '/claim-templates',
        element: <TemplatesClaim />,
      },
      {
        path: '/claim-templates-create',
        element: <TemplatesClaimEdit />,
      },
      {
        path: '/claim-templates-edit',
        element: <TemplatesClaimEdit />,
      },
      {
        path: '/claim-templates-view',
        element: <TemplatesClaimView />,
      },
      {
        path: '/claims-report',
        element: <ReportClaim />,
      },
      {
        path: '/topic-setting',
        lazy: async () => {
          const { TopicSetting } = await import(
            'src/pages/Admin/Claims/TopicSetting'
          )
          return { Component: TopicSetting }
        },
      },
      {
        path: '/topic-create',
        lazy: async () => {
          const { TopicCreate } = await import(
            'src/pages/Admin/Claims/TopicCreate'
          )
          return { Component: TopicCreate }
        },
      },
      {
        path: '/profiles',
        element: <Profiles />,
      },
      {
        path: '/profile-view',
        element: <ProfileView />,
      },
      {
        path: '/verification-applications',
        element: <Verifications />,
      },
      {
        path: '/verification-application-view',
        element: <VerificationView />,
      },
      {
        path: '/supervisor',
        element: <Supervisor />,
      },
      {
        path: '/external-agencies',
        element: <Agencies />,
      },
      {
        path: '/external-agencies-edit',
        element: <AgencyEdit />,
      },
      {
        path: '/external-agencies-view',
        element: <AgencyView />,
      },
      {
        path: '/debts',
        element: <Debts />,
      },
      {
        path: '/debts-queue',
        element: <DebtsQueue />,
      },
      {
        path: '/debts-sell',
        element: <DebtsSell />,
      },
      {
        path: '/queues',
        element: <Queues />,
      },
      {
        path: '/queues-create',
        element: <QueueEdit />,
      },
      {
        path: '/queues-edit',
        element: <QueueEdit />,
      },
      {
        path: '/queues-view',
        element: <QueueView />,
      },
      {
        path: '/segments',
        element: <Segments />,
      },
      {
        path: '/segments-create',
        element: <SegmentEdit />,
      },
      {
        path: '/segments-edit',
        element: <SegmentEdit />,
      },
      {
        path: '/segments-view',
        element: <SegmentView />,
      },
      {
        path: '/groups',
        element: <Groups />,
      },
      {
        path: '/groups-create',
        element: <GroupEdit />,
      },
      {
        path: '/groups-edit',
        element: <GroupEdit />,
      },
      {
        path: '/collectors',
        element: <Collectors />,
      },
      {
        path: '/collectors-edit',
        element: <CollectorEdit />,
      },
      {
        path: '/products',
        element: <Products />,
      },
      {
        path: '/product-create',
        element: <ProductEdit />,
      },
      {
        path: '/product-edit',
        element: <ProductEdit />,
      },
      {
        path: '/product-view',
        element: <ProductView />,
      },
      {
        path: '/external-services-settings',
        element: <ExternalServicesSettings />,
      },
      {
        path: '/external-services-settings-create',
        element: <ExternalServicesSettingsEdit />,
      },
      {
        path: '/external-services-settings-edit',
        element: <ExternalServicesSettingsEdit />,
      },
      {
        path: '/external-services-settings-view',
        element: <ExternalServicesSettingsView />,
      },
      {
        path: '/document-types',
        element: <DocumentTypes />,
      },
      {
        path: '/document-types-create',
        element: <DocumentTypeEdit />,
      },
      {
        path: '/document-types-edit',
        element: <DocumentTypeEdit />,
      },
      {
        path: '/document-types-view',
        element: <DocumentTypeView />,
      },
      {
        path: '/notifications-senders',
        element: <NotificationsSenders />,
      },
      {
        path: '/notifications-senders-create',
        element: <NotificationsSenderEdit />,
      },
      {
        path: '/notifications-senders-edit',
        element: <NotificationsSenderEdit />,
      },
      {
        path: '/notifications-senders-view',
        element: <NotificationsSenderView />,
      },
      {
        path: '/promocodes',
        element: <Promocodes />,
      },
      {
        path: '/promocodes-create',
        element: <PromocodeEdit />,
      },
      {
        path: '/promocodes-edit',
        element: <PromocodeEdit />,
      },
      {
        path: '/promocodes-view',
        element: <PromocodeView />,
      },
      {
        path: '/notifications-mapping-templates',
        element: <NotificationsMappingTemplates />,
      },
      {
        path: '/notifications-mapping-templates-create',
        element: <NotificationMappingTemplateEdit />,
      },
      {
        path: '/notifications-mapping-templates-edit',
        element: <NotificationMappingTemplateEdit />,
      },
      {
        path: '/notifications-mapping-templates-view',
        element: <NotificationMappingTemplateView />,
      },
      {
        path: '/notifications-templates',
        element: <NotificationsTemplates />,
      },
      {
        path: '/notifications-templates-create',
        element: <NotificationTemplateEdit />,
      },
      {
        path: '/notifications-templates-edit',
        element: <NotificationTemplateEdit />,
      },
      {
        path: '/notifications-templates-view',
        element: <NotificationTemplateView />,
      },
      {
        path: '/product-extensions',
        element: <ProductExtensions />,
      },
      {
        path: '/product-extensions-view',
        element: <ProductExtensionView />,
      },
      {
        path: '/product-extensions-edit',
        element: <ProductExtensionEdit />,
      },
      {
        path: '/payments',
        element: <Payments />,
      },
      {
        path: '/payment-view',
        element: <PaymentView />,
      },
      {
        path: '/payment-create',
        element: <PaymentEdit />,
      },
      {
        path: '/roles',
        element: <Roles />,
      },
      {
        path: '/role-view',
        element: <RoleView />,
      },
      {
        path: '/users',
        element: <Users />,
      },
      {
        path: '/user-view',
        element: <UserView />,
      },
      {
        path: '/user-edit',
        element: <UserEdit />,
      },
      {
        path: '/mass-add-tags',
        element: <Tags />,
      },
      {
        path: '/rec-payments',
        element: <RecPayments />,
      },
      {
        path: '/ros-fin-monitoring',
        element: <RosFinMonitoring />,
      },
      {
        path: '/strategy',
        element: <Strategies />,
      },
      {
        path: '/strategy-create',
        element: <StrategyEdit />,
      },
      {
        path: '/strategy-edit',
        element: <StrategyEdit />,
      },
      {
        path: '/strategy-view',
        element: <StrategyView />,
      },
      {
        path: '/strategy/templates',
        element: <Templates />,
      },
      {
        path: '/strategy/templates-view',
        element: <TemplateView />,
      },
      {
        path: '/strategy/templates-edit',
        element: <TemplateEdit />,
      },
      {
        path: '/strategy/templates-create',
        element: <TemplateEdit />,
      },
      {
        path: '/dialogues',
        element: <Dialogues />,
      },
      {
        path: '/dialogue-view',
        element: <DialogueView />,
      },
      {
        path: '/settings',
        element: <Settings />,
      },
      {
        path: '/promotions',
        element: <Promotions />,
      },
      {
        path: '/promotion-view',
        element: <PromotionView />,
      },
      {
        path: '/promotion-edit',
        element: <PromotionEdit />,
      },
      {
        path: '/chats',
        children: [
          {
            path: 'my',
            element: <Chats />,
          },
          {
            path: ':id',
            element: <ChatView />,
          },
          {
            path: 'templates',
            children: [
              {
                element: <ChatsTemplates />,
                index: true,
              },
              {
                path: ':id',
                element: <ChatTemplateView />,
              },

              {
                path: ':id?/edit',
                element: <ChatTemplateEdit />,
              },
            ],
          },
          {
            path: 'topics',
            element: <ChatsTopics />,
          },
          {
            path: 'settings',
            children: [
              {
                index: true,
                element: <ChatUserSettings />,
              },
              {
                path: 'global',
                element: <ChatGlobalSettings />,
              },
              {
                path: ':id',
                element: <ChatUserSettingsEdit />,
              },
            ],
          },
        ],
      },
      {
        path: '/bki-sent-file',
        element: <BkiSent />,
      },
      {
        path: '/companies',
        element: <Companies />,
      },
      {
        path: '/company-edit',
        element: <EditCompany />,
      },
      {
        path: '/company-view',
        element: <ViewCompany />,
      },
    ],
  },
]
