import React, {useState} from "react";

import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

import {useTranslation} from "react-i18next";

import Flatpickr from "react-flatpickr";
import Slider from '@mui/material/Slider';

import {formatAPIDate} from "../../../utils";


const InputItem = ({description, validation, validationKey, className, onChange}) => {
  const {t} = useTranslation();
  const htmlForId = `validation-${validationKey}`;

  const [listItem, setListItem] = useState('-');

  const checkbox = description.isOptional ? (
    <div className="form-check form-check-secondary">
      <Input
        className="form-check-input"
        type="checkbox"
        checked={validation.values[`${validationKey}-enabled`] || false}
        onChange={(e) => {
          validation.setFieldValue(`${validationKey}-enabled`, e.target.checked)
        }}
      />
    </div>
  ) : null;

  const disabled = description.isOptional && !validation.values[`${validationKey}-enabled`];

  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row gap-2">
      {checkbox}
      <Label htmlFor={htmlForId}>{t(description.label)}</Label>
      </div>
      {!description.isList && description.options && description.options.length ?
      <select
        name={`${validationKey}`}
        id={htmlForId}
        className="form-select form-select-sm"
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values[validationKey]}
        disabled={disabled}
      >
        {description.options.map(option =>
          <option value={option.value} key={option.value}>
            {t(option.name)}
          </option>
        )}
      </select>
      : description.dateRange ?
      <Flatpickr
        name={`${validationKey}`}
        id={htmlForId}
        className="form-control form-control-sm"
        options={{
          mode: 'range',
          dateFormat: "Y-m-d",
          onChange: (selectedDates, dateStr) => {
            validation.setFieldValue(validationKey, selectedDates.map(d=>formatAPIDate(d)));
          }
        }}
        value={validation.values[validationKey]}
        style={disabled ? {color: 'gray'} : null}
        disabled={disabled}
      /> : description.numberRange ?
      <div style={{width: '100%', maxWidth: 500, marginTop: 20, paddingLeft: 20, paddingRight: 20}}>
        <Slider
            value={validation.values[validationKey] || [description.min, description.max]}
            disabled={disabled}
            onChange={(e, value) => {
              //console.log('slider value: ', value);
              validation.setFieldValue(validationKey, value)
            }}
            valueLabelDisplay="on"
            step={description.step}
            min={description.min}
            max={description.max}
            sx={{
              '& .MuiSlider-valueLabel': {
                fontSize: 12,
                fontWeight: 'normal',
                top: -6,
                backgroundColor: 'unset',
                color: '#000',
                '&:before': {
                  display: 'none',
                },
                '& *': {
                  background: 'transparent',
                  color: '#000',
                },
              },
            }}
        />
      </div>
      : description.isList ?
      <div>
        <div className="d-flex flex-row gap-2 align-items-center" style={{maxWidth: 300}}>
          {description.options && description.options.length ?
            <select
              id={htmlForId}
              className="form-select form-select-sm"
              onChange={e => setListItem(e.target.value)}
              value={listItem}
              disabled={disabled}
            >
              {description.options.map(option =>
                <option value={option.value} key={option.value}>
                  {t(option.name)}
                </option>
              )}
            </select>
            :
          <Input
            type={description.isNumber ? "number" : "text"}
            className="form-control form-control-sm"
            id={htmlForId}
            onChange={e => setListItem(description.isNumber ? parseInt(e.target.value) : e.target.value)}
            value={listItem}
            disabled={disabled}
          />}
          <div role="button" className="text-decoration-underline" onClick={() => {
            if (!listItem || listItem === '-') return;
            if (validation.values[validationKey].includes(listItem)) return;
            const newList = validation.values[validationKey] ? [...validation.values[validationKey], listItem] : [];
            validation.setFieldValue(validationKey, newList);
          }}>
            <small>{t('Add')}</small>
          </div>
        </div>
        <div className="mt-2 mx-2">
        {validation.values[validationKey]?.map(item =>
          <div className="d-flex flex-row gap-2" key={item}>
            <div style={{width: description.isNumber ? 30 : 'auto'}}>
              {t(description.options?.find(op => op.value == item)?.name) || item}
            </div>
            <div role="button" className="text-decoration-underline" onClick={()=>{
              const newList = validation.values[validationKey].filter(v => v !== item);
              validation.setFieldValue(validationKey, newList);
            }}>
              <small>{t('Remove')}</small>
            </div>
          </div>
        )}
        </div>
      </div>
      :
      <Input
        name={`${validationKey}`}
        placeholder={description.placeholder}
        type={description.isNumber ? "number" : "text"}
        className="form-control form-control-sm"
        id={htmlForId}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values[validationKey]}
        invalid={!!(validation.touched[validationKey] && validation.errors[validationKey])}
        disabled={disabled}
      />}
      {validation.touched[validationKey] && validation.errors[validationKey] ? (
        <FormFeedback type="invalid">
          {validation.errors[validationKey]}
        </FormFeedback>
      ) : null}
    </FormGroup>
  );
}

export default InputItem;
