import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

import Client from "../../../client";
import Tabs from '../../../Components/Common/Tabs';

import {
  Row,
  Col,
  Container,
} from "reactstrap";

const Related = ({application}) => {
  const {t, i18n} = useTranslation();

  const items = [];
  if (application.profile) {
    const {client, ...profile} = application.profile;
    if (client) profile.client_id = client.id;
    items.push(
      {
        title: `${t('Profile')}`,
        entity: profile,
        entityId: profile.id,
        entityType: Client.ENTITY_TYPE.PROFILES,
        icon: null,// 'ri-user-settings-line',
      }
    );
  }

  if (application.client_id)
    items.push(
      {
        title: `${t('Client')}`,
        entity: {...application.profile.client, documents: application.documents},
        entityId: application.client_id,
        entityType: Client.ENTITY_TYPE.CLIENTS,
        icon: null,// 'ri-user-heart-line',
      }
    )

  if (application.status === "ManualVerificationRequired" && application.documents)
    items.push(
      {
        title: `${t('Manual_verification')}`,
        entity: application.documents,
        entityId: application.id,
        entityType: Client.ENTITY_TYPE.DOCUMENTS,
        icon: null,// 'ri-user-heart-line',
      }
    )

  if (application.loan) {
    const {application_id, operations, loan_tranches, funds_transactions, product_copy, ...loan} = application.loan;
    items.push(
      {
        title: `${t('Loan')}`,
        entity: loan,
        entityId: loan.id,
        entityType: Client.ENTITY_TYPE.LOANS,
        icon: null,//'ri-time-line',
      }
    );
  }

  if (application.verificationHistory && application.verificationHistory.length > 0)
    items.push(
      {
        title: `${t('Verification history')}`,
        entity: application.verificationHistory,
        entityType: 'application_verification_history',
      }
    );

  return <Tabs items={items} />;
};

export default Related;
