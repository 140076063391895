import { useTranslation } from 'react-i18next'

import Client from '../../../client'
import Tabs from '../../../Components/Common/Tabs'

const Related = ({ client }) => {
  const { t } = useTranslation()

  const items = []

  if (client.addon_applications) {
    items.push({
      title: `${t('Applications')}`,
      entity: client.addon_applications,
      entityType: 'addon_applications',
      type: Client.ENTITY_TYPE.APPLICATIONS,
    })
  }

  if (client.addon_loans) {
    items.push({
      title: `${t('Loans')}`,
      entity: client.addon_loans,
      entityType: 'addon_loans',
      type: Client.ENTITY_TYPE.LOANS,
    })
  }

  if (client.addon_payments?.length) {
    items.push({
      title: `${t('Payments')}`,
      entity: client.addon_payments,
      entityType: 'addon_payments',
      type: Client.ENTITY_TYPE.PAYMENTS,
    })
  }

  if (client.notifications) {
    items.push({
      title: `${t('Notifications')}`,
      entity: client.notifications,
      entityType: 'profile_notifications',
    })
  }

  if (client.documentsSigned) {
    items.push({
      title: `${t('DocumentsSigned')}`,
      entity: client.documentsSigned,
      entityType: 'documents_signed',
    })
  }

  items.push({
    title: `${t('tags')}`,
    entityId: client.id,
    entityType: 'clients_tags',
  })

  items.push({
    title: `${t('Block_client')}`,
    entity: client,
    entityType: 'block_client',
    application: client,
  })

  items.push({
    title: `${t('History claims')}`,
    entityId: client.id,
    profileId: client.profile_id,
    entityType: 'history_claims',
  })

  console.log(client, '!!!')

  return <Tabs items={items} />
}

export default Related
