import React from "react";
import Table4Columns from "./Table4Columns";

const columnsDescriptionDefault = [
  ['id'],
  ['created_at'],
  ['updated_at'],
  ['application_status'],
  ['new_line'],
  ['requested_amount', 'requested_period'],
  ['approved_amount', 'approved_period'],
  ['signed_amount', 'signed_period'],
  ['new_line'],
  ['smscode', 'smscode_sent_at'],
  ['smscode_agreement', 'smscode_agreement_sent_at'],
];

const fieldsDescription = {
  id: {isLink: true, linkFieldName: 'application_link'},
  new_line: {isEmpty: true, newLine: true},
  created_at: {isDateTime: true},
  updated_at: {isDateTime: true},
  requested_amount: {isAmount: true},
  approved_amount: {isAmount: true},
  signed_amount: {isAmount: true},
  application_status: {fieldName: 'status'},
  smscode: {},
  smscode_sent_at: {isDateTime: true},
  smscode_agreement: {},
  smscode_agreement_sent_at: {isDateTime: true},
};

const EntityContent = ({entity, style, columnsDescription}) => {
  return (
    <Table4Columns
      columnsDescription={columnsDescription || columnsDescriptionDefault}
      fieldsDescription={fieldsDescription}
      className="table-sm align-middle mb-0"
      style={{width: 'max-content'}}
      entity={{...entity, application_link: `/application-view?id=${entity.id}`}}
    />
  );
}

export default EntityContent;
