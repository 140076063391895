import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { Modal, ModalBody, ModalHeader } from 'reactstrap'

export const AskAboutAction = (props: {
  sureAction: any
  cancelAction: any
  isOpen: boolean
  toggle: () => void
  title?: string
}) => {
  const { sureAction, cancelAction, isOpen, toggle, title } = props
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{t(`${title}`)}</ModalHeader>
      <ModalBody>
        <div>
          <Button
            onClick={sureAction}
            color="danger"
            style={{ marginTop: '10px', marginBottom: '5px' }}
          >
            {t('Yes, I am sure')}
          </Button>
          <Button
            onClick={cancelAction}
            color="success"
            style={{
              marginTop: '10px',
              marginBottom: '5px',
              marginLeft: '30px',
            }}
          >
            {t('No, cancel')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
