import React, {useEffect, useRef, useState} from "react";
import {
  Button,
  Label,
  FormGroup,
  Form,
} from "reactstrap";
import {useTranslation} from "react-i18next";
import Flatpickr from "react-flatpickr";
import flatpickr from "flatpickr";

import List from "./List";
import Client from "../../../client";
import {formatAPIDate} from "../../../utils";

const fieldsDescription = {
  id: {},
  begin_date: {label: 'holiday_begin_date', isDate: true},
  end_date: {label: 'holiday_end_date', isDate: true},
  end_date: {label: 'holiday_end_date', isDate: true},

  full_name: {},
  short_name: {},
  registry_email: {},
  communication_phone: {},
  //created_at: {isDateTime: true},
};

const EntityContent = (props) => {
  const {entityId, style} = props;
  const {t, i18n} = useTranslation();
  const [list, setList] = useState([]);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);

  const fetchData = () => {
    Client.getLoanAgencies(entityId)
    .then(res => {
      console.log('getLoanAgencies', res);
      setList(res.map(agency => ({...agency, ...agency.agency})));
    })
    .catch(err => {
      setList([]);
    })
  }

  useEffect(() => {
    if (props.visible) {
      fetchData();
    }
  }, [props.visible]);

  return (
  <div>
    <List list={list} fieldsDescription={fieldsDescription} style={style} />
    {error && <div className="mt-2 text-danger">{error.toString()}</div>}
  </div>
);
}

export default EntityContent;
