import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import { OffsetNotice } from 'src/Components/Common/LimitNotice'
import ErrorPage from '../ErrorPage'

import { useTranslation } from 'react-i18next'

import model from 'src/model'
import Client from 'src/client'

import {
  getEntities,
  deleteEntities,
  setEntitiesFilter,
} from 'src/store/actions'

import { formatDateTime, formatAmount } from 'src/utils'

const enableActions = false
const enableDelete = false
const enableEdit = false

const headers = {
  id: { name: '' },
  client: { name: 'client' },
  mobile: { name: 'mobile' },
  email: { name: 'email' },
  status: { name: 'status' },
  updated_at: { name: 'created_at' },
  stage: { name: 'stage' },
  requested_amount: { name: 'requested_amount', right: true },
  requested_period: { name: 'requested_period', right: true },
  approved_amount: { name: 'approved_amount', right: true },
  approved_period: { name: 'approved_period', right: true },
  signed_amount: { name: 'signed_amount', right: true },
  signed_period: { name: 'signed_period', right: true },
  verificator_surname: { name: 'verificator_surname', right: true },
}

function valueAlign(header) {
  if (header.right) return 'right'
  return 'left'
}

function headerStyle(header) {
  let textAlign = 'start'
  if (header.right) textAlign = 'end'
  return { textAlign, whiteSpace: 'break-spaces' }
}

export const Applications = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { list, listError, fieldsFilter } = useSelector((state) => ({
    list: state.entities[Client.ENTITY_TYPE.APPLICATIONS].list,
    listLoading: state.entities[Client.ENTITY_TYPE.APPLICATIONS].listLoading,
    listError: state.entities[Client.ENTITY_TYPE.APPLICATIONS].listError,
    //strFilter: state.entities[Client.ENTITY_TYPE.APPLICATIONS].strFilter,
    fieldsFilter: state.entities[Client.ENTITY_TYPE.APPLICATIONS].fieldsFilter,
  }))

  //const [strFilterList, setStrFilterList] = useState(strFilter);
  const [fieldsFilterList, setFieldsFilterList] = useState(fieldsFilter)
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    //dispatch(getEntities(Client.ENTITY_TYPE.APPLICATIONS, null, {...fieldsFilterList, keyword: strFilterList }));
  }, [dispatch])

  /*useEffect(() => {
    dispatch(setEntitiesFilter(Client.ENTITY_TYPE.APPLICATIONS, strFilterList, fieldsFilterList));
    clearTimeout(debounceTimers[`filtered-entities-${strFilterList}`]);
    debounceTimers[`filtered-entities-${strFilterList}`] = setTimeout(() => {
      console.log(`request list with ${strFilterList}`);
      dispatch(getEntities(Client.ENTITY_TYPE.APPLICATIONS, null, {keyword: strFilterList}));

    }, DEBOUNCE_TIMEOUT);

    return ()=>{
      if (debounceTimers[`filtered-entities-${strFilterList}`]) {
        clearTimeout(debounceTimers[`filtered-entities-${strFilterList}`]);
      }
    }
  }, [strFilterList]);*/

  useEffect(() => {
    dispatch(
      setEntitiesFilter(Client.ENTITY_TYPE.APPLICATIONS, '', fieldsFilterList),
    )

    //const fieldsFilterListJson = JSON.stringify(fieldsFilterList);
    //clearTimeout(debounceTimers[`filtered-entities-${fieldsFilterListJson}`]);
    //debounceTimers[`filtered-entities-${fieldsFilterListJson}`] = setTimeout(() => {
    //  console.log(`request list with ${fieldsFilterListJson}`);
    if (fieldsFilterList.gender)
      fieldsFilterList.gender = model.getGenderCode(fieldsFilterList.gender)
    dispatch(
      getEntities(Client.ENTITY_TYPE.APPLICATIONS, null, fieldsFilterList),
    )

    //}, DEBOUNCE_TIMEOUT);

    //return ()=>{
    //  if (debounceTimers[`filtered-entities-${fieldsFilterListJson}`]) {
    //    clearTimeout(debounceTimers[`filtered-entities-${fieldsFilterListJson}`]);
    //  }
    //}
  }, [fieldsFilterList])

  const next = () => {
    if (list.length < model.LIST_LIMIT) return
    dispatch(
      getEntities(
        Client.ENTITY_TYPE.APPLICATIONS,
        null,
        fieldsFilter,
        offset + model.LIST_LIMIT,
      ),
    )
    setOffset((prev) => prev + model.LIST_LIMIT)
  }

  const prev = () => {
    if (offset === 0) return
    dispatch(
      getEntities(
        Client.ENTITY_TYPE.APPLICATIONS,
        null,
        fieldsFilter,
        offset - model.LIST_LIMIT,
      ),
    )
    setOffset((prev) => Math.max(prev - model.LIST_LIMIT, 0))
  }

  const archive = (id) => {
    dispatch(deleteEntities(id, Client.ENTITY_TYPE.APPLICATIONS))
  }

  console.log('applications =>', list)
  const title = t('Applications')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  //console.log('strFilterList', strFilterList);
  console.log('fieldsFilterList', fieldsFilterList)

  const minimizeTextStyle = {
    textOverflow: 'ellipsis',
    maxWidth: 200,
    overflow: 'hidden',
  }

  const bread = <BreadCrumb title={title} />
  return (
    <>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            <div className="table-responsive table-card">
              <FieldsFilter
                fields={{
                  application_id: {},
                  application_status: {
                    options: [
                      'Received',
                      'Autoverification',
                      'ManualVerificationRequired',
                      'ManualVerification',
                      'RevisionRequired',
                      'Approved',
                      'Declined',
                      'Signed',
                      'NotSigned',
                      'Issued',
                    ],
                  },
                  loan_id: {},
                  created_at: { date: true },
                  client_id: {},
                  profile_id: {},
                  phone: {},
                  email: {},
                  group_delimiter: {},
                  first_name: {},
                  last_name: {},
                  middle_name: {},
                  birth_date: { date: true },
                  gender: { options: ['male', 'female'] },
                  application_short_name: {},
                  verificator_surname: {},
                  //birth_place: {},
                  //passport_number: {},
                  //passport_code: {},
                  //inn: {},
                  //snils: {},
                }}
                applyFilter={(values) => setFieldsFilterList(values)}
                resetFilter={() => setFieldsFilterList({})}
                filter={{
                  ...fieldsFilterList,
                  gender: model.getStrGender(fieldsFilterList.gender),
                }}
              />

              <div
                className="btn-group gap-2 p-3"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn btn-primary d-flex align-items-center"
                  disabled={offset === 0}
                  onClick={prev}
                >
                  <i className="ri-arrow-left-line fs-15 me-2" />
                  {`${t('Prev')} ${model.LIST_LIMIT}`}
                </button>
                <button
                  type="button"
                  className="btn btn-primary d-flex align-items-center"
                  disabled={list?.length < model.LIST_LIMIT}
                  onClick={next}
                >
                  {`${t('Next')} ${model.LIST_LIMIT}`}
                  <i className="ri-arrow-right-line fs-15 ms-2" />
                </button>
              </div>

              <OffsetNotice offset={offset} limit={model.LIST_LIMIT} />
              <Table hover className="table-sm align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    {Object.keys(headers).map((header) => (
                      <th
                        style={headerStyle(headers[header])}
                        key={header}
                        scope="col"
                      >
                        {t(headers[header].name)}
                      </th>
                    ))}
                    {enableActions && <th scope="col">{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.length > 0 &&
                    list.map((entity) => {
                      return (
                        <tr key={entity.id}>
                          {Object.keys(headers).map((fieldName) => {
                            const value = entity[fieldName]
                            const key = `${fieldName}`
                            if (fieldName === 'id')
                              return (
                                <th scope="row" key={key}>
                                  <Link
                                    to={`/application-view?id=${value}`}
                                    className="fw-medium"
                                  >
                                    <i className="ri-eye-line fs-15" />
                                  </Link>
                                </th>
                              )

                            if (fieldName === 'mobile')
                              return (
                                <td key={key} style={minimizeTextStyle}>
                                  {entity.profile_phone}
                                </td>
                              )
                            if (fieldName === 'email')
                              return (
                                <td key={key} style={minimizeTextStyle}>
                                  {entity.profile_email}
                                </td>
                              )
                            if (fieldName === 'client')
                              return (
                                <td
                                  key={key}
                                  style={{
                                    whiteSpace: 'nowrap',
                                    ...minimizeTextStyle,
                                  }}
                                >
                                  {`${entity.client_surname} ${entity.client_name} ${entity.client_midname}`}
                                </td>
                              )

                            if (fieldName === 'updated_at')
                              return (
                                <td key={key} style={minimizeTextStyle}>
                                  {formatDateTime(
                                    entity.updated_at || entity.created_at,
                                  )}
                                </td>
                              )

                            if (
                              fieldName === 'approved_amount' ||
                              fieldName === 'requested_amount' ||
                              fieldName === 'signed_amount'
                            )
                              return (
                                <td
                                  style={minimizeTextStyle}
                                  align={valueAlign(headers[fieldName])}
                                  key={key}
                                >
                                  {formatAmount(value)}
                                </td>
                              )

                            return (
                              <td
                                style={minimizeTextStyle}
                                align={valueAlign(headers[fieldName])}
                                key={key}
                              >
                                {value || '-'}
                              </td>
                            )
                          })}
                          {enableActions && (
                            <td>
                              <div className="hstack gap-3 flex-wrap">
                                {
                                  <Link
                                    to={`/application-view?id=${entity.id}`}
                                    className="link-success fs-15"
                                  >
                                    <i className="ri-eye-line"></i>
                                  </Link>
                                }
                                {enableEdit && (
                                  <Link
                                    to={`/application-view?id=${entity.id}&edit=true`}
                                    className="link-success fs-15"
                                  >
                                    <i className="ri-edit-2-line"></i>
                                  </Link>
                                )}
                                {enableDelete && (
                                  <Link
                                    to="#"
                                    onClick={() => archive(entity.id)}
                                    className="link-danger fs-15"
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                  </Link>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
