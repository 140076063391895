import { call, put, takeEvery, all, fork } from 'redux-saga/effects'

// Crypto Redux States
import { LIST, DELETE, UPDATE } from './actionType'

import {
  getClients as getClientsAction,
  getClientsFail,
  getClientsSuccess,
  deleteClientSuccess,
  deleteClientFail,
  updateClientSuccess,
  updateClientFail,
} from './action'

import Client from '../../client'

function* deleteClient({ payload }) {
  try {
    const response = yield call(Client.deleteClient, payload)
    yield put(deleteClientSuccess())
    yield put(getClientsAction())
  } catch (error) {
    yield put(deleteClientFail(error))
  }
}

function* updateClient({ payload }) {
  try {
    const response = yield call(Client.updateClient, payload.formData)
    const responseWork = yield call(Client.updateClientWork, payload.workData)
    const responseAddress = yield call(
      Client.updateClientAddresses,
      payload.addressData,
    )
    const responseDocs = yield call(
      Client.updateClientDocuments,
      payload.documentsData,
    )
    yield put(updateClientSuccess())
    yield put(getClientsAction(payload.formData.get('client_id')))
  } catch (error) {
    yield put(updateClientFail(error))
  }
}

function* getClients({ id, filter }) {
  try {
    if (id) {
      const client = yield call(
        Client.getEntity,
        Client.ENTITY_TYPE.CLIENTS,
        id,
      )
      if (client.profile_id) {
        try {
          const notifications = yield call(
            Client.getProfileNotifications,
            client.profile_id,
          )
          client.notifications = notifications
        } catch (err) {
          client.notifications = []
        }
      }

      try {
        const documents = yield call(Client.getClientDocuments, client.id)
        client.documents = documents.documents
      } catch (err) {
        client.documents = []
      }

      try {
        const documents = yield call(Client.getClientSignedDocs, client.id)
        client.documentsSigned = documents
      } catch (err) {
        client.documentsSigned = []
      }

      try {
        const addonTypes = [
          Client.ENTITY_TYPE.APPLICATIONS,
          Client.ENTITY_TYPE.LOANS,
          Client.ENTITY_TYPE.PAYMENTS,
        ]
        const res = yield all(
          addonTypes.map((type) =>
            call(Client.getClientAddons, client.id, type),
          ),
        )
        addonTypes.forEach((type, rank) => {
          client[`addon_${type}`] = res[rank]
        })
      } catch (err) {
        //nothing to do
      }

      yield put(getClientsSuccess([client]))
    } else {
      const response = yield call(
        Client.getList,
        Client.ENTITY_TYPE.CLIENTS,
        filter,
      )
      yield put(getClientsSuccess(response))
    }
  } catch (error) {
    console.log(error)
    yield put(getClientsFail(error))
  }
}

export function* watchOnGetList() {
  yield takeEvery(LIST, getClients)
}

export function* watchOnDelete() {
  yield takeEvery(DELETE, deleteClient)
}

export function* watchOnUpdate() {
  yield takeEvery(UPDATE, updateClient)
}

function* clientsSaga() {
  yield all([fork(watchOnGetList), fork(watchOnDelete), fork(watchOnUpdate)])
}

export default clientsSaga
