import React, {useEffect, useRef, useState} from "react";
import {
  Button,
  Label,
  FormGroup,
  Form,
} from "reactstrap";
import {useTranslation} from "react-i18next";
import Flatpickr from "react-flatpickr";
import flatpickr from "flatpickr";

import List from "./List";
import Client from "../../../client";
import {formatAPIDate} from "../../../utils";

const fieldsDescription = {
  begin_date: {label: 'holiday_begin_date', isDate: true},
  end_date: {label: 'holiday_end_date', isDate: true},
  is_active: {},
  created_at: {isDateTime: true},
};

const EntityContent = (props) => {
  const {entityId, style} = props;
  const {t, i18n} = useTranslation();
  const [list, setList] = useState([]);
  const [error, setError] = useState('');
  const [holidays, setHolidays] = useState([]);

  const fetchTranches = () => {
    Client.getLoanHolidays(entityId)
    .then(res => {
      console.log('getLoanHolidays', res);
      setList(res ? [res] : []);
    });
  }

  const create = () => {
    setError('');
    if (!holidays || holidays.length !== 2) {
      setError(t('Select dates'));
    }

    Client.addLoanHolidays(entityId, holidays[0], holidays[1])
    .then(res => {
      fetchTranches();
    })
    .catch(err => {
      setError(err);
    });
  }

  const edit = () => {
    setError('');
    if (!holidays || holidays.length !== 2) {
      setError(t('Select dates'));
    }

    Client.editLoanHolidays(entityId, holidays[0], holidays[1])
    .then(res => {
      fetchTranches();
    })
    .catch(err => {
      setError(err);
    });
  }

  useEffect(() => {
    if (props.visible) {
      fetchTranches();
    }
  }, [props.visible]);

  return (
  <div>
    <List list={list} fieldsDescription={fieldsDescription} style={style} />
    {error && <div className="mt-2 text-danger">{error.toString()}</div>}

    <Form className="needs-validation mt-4">
    <FormGroup>
    <div className="d-flex flex-row gap-3 align-items-center">
      <Label htmlFor={'holidays'}>{t('dates')}</Label>
      <Flatpickr
        name={'holidays'}
        id={'holidays'}
        style={{maxWidth: 200}}
        className="form-control form-control-sm"
        options={{
          mode: 'range',
          dateFormat: "d-m-Y",
          onChange: (selectedDates, dateStr) => {
            setHolidays(selectedDates.map(d => formatAPIDate(d)));
          }
        }}
      />
      <Button
        className="btn-label"
        size='sm'
        color="primary"
        style={{backgroundColor: '#405189'}}
        onClick={list && list.length > 0 ? edit : create}
      >
        <i className="ri-add-line label-icon align-middle fs-16 me-2"></i>
        {t(list && list.length > 0 ? "Update" : "Create")}
      </Button>
    </div>
    </FormGroup>
    </Form>
  </div>
);
}

export default EntityContent;
