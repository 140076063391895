import React, { useEffect, useState } from "react";
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {formatDate, formatDateTime, formatAmount} from "../../../utils";
import Client from "../../../client";
import {Photos} from "./Client";

import model from "../../../model";


const fieldsDescription = {
  //request_id: {isShortId: false},
  created_at: {isDateTime: true},
  updated_at: {isDateTime: true},
  //status: {},
  description: {},
  fraud: {},
  is_photoshop: {},
  same_fase_and_other_name: {},
};

export const VerificationHistory = (props) => {
  const {t, i18n} = useTranslation();
  const [show, setShow] = useState(false);
  const [decisionStatuses, setDecisionStatuses] = useState([]);

  useEffect(() => {
    Client.decisionStatuses()
    .then(res => {
      //console.log('VerificationHistory::Client.decisionStatuses() =>', res);
      const statuses = res.map(option => {
        return {id: option.id, text: option.description}
      });

      setDecisionStatuses(statuses);
    })
    .catch((error) => {
      console.log('error getting decision statuses', error);
      setDecisionStatuses([]);
    })
  },[]);

  const data = props.entity.filter(item => !!item.data).map(item =>({...item.data, verifier: item.verifier, updated_at: item.updated_at, created_at: item.created_at, file: item.file}));

  return (
    <div className={props.className}>
      <div className="fs-7 text-info text-decoration-underline" role="button" onClick={()=>setShow(prev => !prev)}>
        {t('verification_history')}
      </div>
      <div className="">
        {show && !data.length && <small>{t('No_decisions')}</small>}
        {show && data.length > 0 && data.map(historyItem => {
          const filtered = decisionStatuses?.filter(st => st.id === historyItem.status);
          const strStatus = filtered.length ? filtered[0].text : '';
          return (
            <div key={historyItem.request_id} className="shadow rounded border p-2 fs-7 mt-3">
              <div>
                {t('request_id')}: <span className="fw-bold">{t(model.fieldValueToText('request_id', historyItem, fieldsDescription))}</span>
              </div>

              <div>{t('verifier')}: {historyItem.verifier}</div>

              {strStatus && <div>{t('status')}: {strStatus}</div>}

              {Object.keys(fieldsDescription).map(fieldName => (
                Object.keys(historyItem).includes(fieldName) ? <div key={`${historyItem.request_id}-${fieldName}`}>
                  <div>{`${t(fieldName)}: ${t(model.fieldValueToText(fieldName, historyItem, fieldsDescription))}`}</div>
                </div> : null
              ))}
              {
                historyItem.photo?.length > 0 &&
                <Photos
                  className="mt-2 mb-2"
                  decisionView={props.decisionView}
                  photos={historyItem.photo.map(photo => ({...photo, id: photo.file_id, is_incorrect: photo.incorrect}))}
                />
              }
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default VerificationHistory;
