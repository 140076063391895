import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'

import Client from 'src/client'

import { getEntities, updateEntities, createEntities } from 'src/store/actions'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

const inputInitials = {
  phone: { label: 'phone', initial: '', placeholder: '' },
  email: { label: 'email', initial: '', placeholder: '' },
  password: { label: 'password', initial: '', placeholder: '' },
  surname: { label: 'last_name', initial: '', placeholder: '' },
  name: { label: 'first_name', initial: '', placeholder: '' },
  midname: { label: 'middle_name', initial: '', placeholder: '' },
}

export const UserEdit = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [id, setId] = useState(null)
  const [entity, setEntity] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [success, setSuccess] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState(null)
  const {
    entityLoaded,
    entityLoading,
    updateLoading,
    updateError,
    createError,
    createLoading,
  } = useSelector((state) => ({
    entityLoaded: state.entities[Client.ENTITY_TYPE.USER].entity,
    entityLoading: state.entities[Client.ENTITY_TYPE.USER].entityLoading,
    entityError: state.entities[Client.ENTITY_TYPE.USER].entityError,
    updateError: state.entities[Client.ENTITY_TYPE.USER].updateError,
    createError: state.entities[Client.ENTITY_TYPE.USER].createError,
    updateLoading: state.entities[Client.ENTITY_TYPE.USER].updateLoading,
    createLoading: state.entities[Client.ENTITY_TYPE.USER].createLoading,
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    console.log('id => ', id)
    setId(id || null)
    if (id) {
      //edit mode
      dispatch(getEntities(Client.ENTITY_TYPE.USER, id))
    } else {
      //create mode
      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        initialFormValues[key] = inputInitials[key].initial
      })
      setInitialFormValues(initialFormValues)
      setPageReady(true)
    }
  }, [])

  useEffect(() => {
    if (id && entityLoading === false) {
      //edit mode
      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        initialFormValues[key] =
          entityLoaded &&
          entityLoaded[key] !== null &&
          entityLoaded[key] !== undefined
            ? entityLoaded[key].toString()
            : ''
      })
      console.log('edit mode, init fields =>', initialFormValues)
      setInitialFormValues(initialFormValues)
      setEntity(entityLoaded)
      setPageReady(true)
    }
  }, [entityLoading])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({
      /*
      notify_channel: Yup.string().test('non-empty', t('Cannot_be_empty'), name => name && name.trim().length > 0).required(`${t("Required")}: ${t('notify_channel')}`),
      subject: Yup.string().test('non-empty', t('Cannot_be_empty'), name => name && name.trim().length > 0).required(`${t("Required")}: ${t('subject')}`),
      template_text: Yup.string().test('non-empty', t('Cannot_be_empty'), name => name && name.trim().length > 0).required(`${t("Required")}: ${t('template_text')}`),
      sender_id: Yup.string().test('non-empty', t('Cannot_be_empty'), name => name && name.trim().length > 0).required(`${t("Required")}: ${t('sender_id')}`),
      */
    }),
    onSubmit: (values) => {
      console.log('values', values)
      const { ...data } = values
      if (id) dispatch(updateEntities({ id, ...data }, Client.ENTITY_TYPE.USER))
      else dispatch(createEntities(data, Client.ENTITY_TYPE.USER))
      setSuccess(true)
    },
  })

  const title = `${t('Notifications_templates')}: ${
    id ? t('Edit') : t('Create')
  }`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const loading = <Spinner color="primary">{t('Loading...')}</Spinner>
  const metaBar = <MetaBar backLink={'/users'} entity={entity} />

  if (!pageReady || entityLoading) {
    return <LoadingPage backLink={'/users'} title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Form
              className="needs-validation mt-4"
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                {Object.keys(inputInitials).map((fieldKey, i) => {
                  console.log(
                    `validation.values[${fieldKey}] => `,
                    validation.values[fieldKey],
                  )
                  const htmlForId = `validation-${fieldKey}`
                  return (
                    <Col
                      className={
                        inputInitials[fieldKey].fullWidth
                          ? 'col-12'
                          : 'col-auto'
                      }
                      key={`${fieldKey}`}
                    >
                      <FormGroup className="mb-3">
                        <Label htmlFor={htmlForId}>
                          {t(inputInitials[fieldKey].label)}
                        </Label>
                        {inputInitials[fieldKey].options &&
                        inputInitials[fieldKey].options.length ? (
                          <select
                            name={`${fieldKey}`}
                            id={htmlForId}
                            className="form-select form-select-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey]}
                          >
                            {inputInitials[fieldKey].options.map((option) => (
                              <option
                                key={option.name}
                                defaultValue={option.defaultValue}
                              >
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <Input
                            name={`${fieldKey}`}
                            placeholder={inputInitials[fieldKey].placeholder}
                            type="text"
                            className="form-control form-control-sm"
                            id={htmlForId}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey] || ''}
                            invalid={
                              !!(
                                validation.touched[fieldKey] &&
                                validation.errors[fieldKey]
                              )
                            }
                          />
                        )}
                        {validation.touched[fieldKey] &&
                        validation.errors[fieldKey] ? (
                          <FormFeedback type="invalid">
                            {validation.errors[fieldKey]}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  )
                })}
              </Row>

              {!!updateError && (
                <Alert color="warning">
                  <strong> {updateError.toString()} </strong>
                </Alert>
              )}
              {!!createError && (
                <Alert color="warning">
                  <strong> {createError.toString()} </strong>
                </Alert>
              )}

              {!updateError && !createError && success && (
                <Alert color="success">
                  <strong> {t('Operation success')} </strong>
                </Alert>
              )}

              {updateLoading || createLoading ? (
                loading
              ) : (
                <Button
                  className="text-nowrap"
                  color="primary"
                  style={{ backgroundColor: '#405189' }}
                  type="submit"
                >
                  {id ? t('Update') : t('Create')}
                </Button>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
