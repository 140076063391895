import { api } from './api'

type Profile = {
  id: string
  created_at: string
  updated_at: string
  email: string
  phone: string
  profile_status: string
  blocked_until: string
  last_timestamp: number
  last_login: string
  client: {
    id: string
    profile_id: string
    created_at: string
    last_name: string
    first_name: string
    middle_name: string
    gender: number
    phone: string
    email: string
    inn: string
    snils: string
    birth_date: string
    birth_place: string
    series: string
    number: string
    docdate: string
    docorgcode: string
    docorg: string
    addresses: any
    addresses_label: any
    typework: string
    work_address: string
    occupation: string
    salary: number
    extsalary: number
    work_phone: string
    phonework_add: string
    contact_person_name_1: string
    contact_person_phone_1: string
    current_address_city: string
    current_address_street: string
    current_address_house: string
    current_address_apt: string
    fact_address_city: string
    fact_address_street: string
    fact_address_house: string
    fact_address_apt: string
  }
}

const profilesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<Profile, string>({
      query: (id) => `crm/v1/profiles/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Profiles', id }],
    }),
  }),
})

export const { useGetProfileQuery } = profilesApi
