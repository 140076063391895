import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import List from 'src/Components/Common/EntitiesView/List'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import Client from 'src/client'

import { useTranslation } from 'react-i18next'

import { getEntities } from 'src/store/actions'

const fieldsDescription = {
  loan_id: { isShortId: false, isLink: true, linkFieldName: 'id_link' },
  begin_date: { label: 'holiday_begin_date', isDate: true },
  end_date: { label: 'holiday_end_date', isDate: true },
  is_active: {},
  created_at: { isDateTime: true },
}

export const Holidays = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { list, listLoading, listError } = useSelector((state) => {
    return {
      list: state.entities[Client.ENTITY_TYPE.HOLIDAYS].list,
      listError: state.entities[Client.ENTITY_TYPE.HOLIDAYS].listError,
      listLoading: state.entities[Client.ENTITY_TYPE.HOLIDAYS].listLoading,
    }
  })

  useEffect(() => {
    dispatch(getEntities(Client.ENTITY_TYPE.HOLIDAYS))
  }, [dispatch])

  console.log('holidays =>', list)
  const title = t('Holidays')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  const bread = <BreadCrumb title={title} />
  return (
    <>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            <div className="live-preview">
              <div className="table-responsive table-card">
                <List
                  style={{ width: 'fit-content' }}
                  list={list.map((item) => ({
                    ...item,
                    id_link: `/loan-view?id=${item.loan_id}`,
                  }))}
                  fieldsDescription={fieldsDescription}
                  className="table-sm align-middle table-nowrap mb-0"
                  hover
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
