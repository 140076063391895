import { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Alert,
  Form,
  Col,
  Row,
  InputGroup,
  InputGroupText,
} from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { OffsetNotice } from 'src/Components/Common/LimitNotice'
import List from 'src/Components/Common/EntitiesView/List'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import Flatpickr from 'react-flatpickr'
import { ToastContainer, toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import model from 'src/model'

import { useTranslation } from 'react-i18next'
import {
  useAutoSalePortfolioMutation,
  useDeleteCreditBureauToSellMutation,
  useGetCreditBureauCompaniesQuery,
  useGetCreditBureauStatusQuery,
  useLazyGetZipCreditBureauQuery,
  useUploadCreditBureauToSellMutation,
} from 'src/services/debts'

const fieldsDescription = {
  company_name: {},
  status: {},
  created_at: { isDate: true },
  loan_id: {},
}

export const DebtsSell = () => {
  const { t } = useTranslation()
  const [showGetZip, setShowGetZip] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<string>('')
  const [file, setFile] = useState<any>(null)
  const [selected, setSelected] = useState<any>([])
  const [offset, setOffset] = useState(0)
  const [fieldCompanyError, setFieldCompanyError] = useState(false)
  const title = t('Debts Sell')
  document.title = title
  const bread = (
    <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
  )

  const {
    data: companies,
    isLoading: companiesLoading,
    isError: companiesError,
  } = useGetCreditBureauCompaniesQuery({})
  const {
    data: status,
    isLoading: statusLoading,
    isError: statusError,
    refetch: refetchStatus,
  } = useGetCreditBureauStatusQuery({ offset: offset, limit: 50 })
  const [
    trigger,
    { isLoading: zipLoading, isSuccess: zipSuccess, error: zipError },
  ] = useLazyGetZipCreditBureauQuery()

  const [sell, { isLoading: sellPortfolio, isSuccess: successSell }] =
    useUploadCreditBureauToSellMutation()
  const [remove, { isLoading: removePortfolio, isSuccess: successRemove }] =
    useDeleteCreditBureauToSellMutation()

  const [autoSale] = useAutoSalePortfolioMutation()

  const removeFromSell = async () => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const reqBody = {
        file: formData,
        company_id: selected,
      }
      await remove(reqBody).unwrap()
      refetchStatus()
    } catch (error: any) {}
  }

  const next = () => {
    if (status.length < model.LIST_LIMIT) return
    setOffset((prev) => prev + model.LIST_LIMIT)
  }

  const prev = () => {
    if (offset === 0) return
    setOffset((prev) => Math.max(prev - model.LIST_LIMIT, 0))
  }

  const getLink = async () => {
    try {
      await trigger({
        company_id: selected,
        sell_date: selectedDate,
      })
    } catch (error: any) {}
  }

  const errorAlert = (text: string) => {
    toast.error(t(`${text}`), {
      position: 'top-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })
  }

  const successAlert = (text: string) => {
    toast.success(t(`${text}`), {
      position: 'top-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })
  }

  useEffect(() => {
    if (successSell) {
      successAlert(`Portfolio Loaded`)
    }
    if (successRemove) {
      errorAlert(`Portfolio Removed`)
    }
    if (zipSuccess) {
      successAlert(`Link sended to your Email`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipSuccess, successSell, successRemove, zipError])

  const downloadFile = async () => {
    const blob = await autoSale()
    if ('data' in blob) {
      const url = window.URL.createObjectURL(new Blob([blob.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `auto_sale_portfolio.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_id: '',
      cession_number: '',
      cession_date: '',
      transfer_date: '',
      cession_price: 0,
    },
    validationSchema: Yup.object().shape({
      cession_number: Yup.string().nullable(),
      cession_date: Yup.string().nullable(),
      transfer_date: Yup.string().nullable(),
      cession_price: Yup.number().nullable(),
    }),
    onSubmit: (values) => {
      const formData = new FormData()
      formData.append('file', file)
      const reqBody = {
        file: formData,
        params: {
          company_id: formik.values.company_id,
          cession_number: formik.values.cession_number,
          cession_date: formik.values.cession_date,
          transfer_date: formik.values.transfer_date,
          cession_price: Number.isInteger(values.cession_price)
            ? values.cession_price / 100
            : 0,
        },
      }
      sell(reqBody)
      refetchStatus()
    },
  })

  if (companiesError || statusError) {
    return (
      <ErrorPage backLink={undefined} title={undefined} error={undefined} />
    )
  }

  if (
    companiesLoading ||
    statusLoading ||
    zipLoading ||
    sellPortfolio ||
    removePortfolio
  )
    return <LoadingPage backLink={undefined} title={undefined} />

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="d-flex flex-column gap-3">
                <Col>
                  <Label htmlFor="">{t('Enter file')}</Label>
                  <Input
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        setFile(e.target.files[0])
                      }
                    }}
                    name={t('Load portfolio')}
                    accept=".csv"
                  />
                </Col>
              </div>
              <Row className="mt-3">
                <Col md="3">
                  <Label htmlFor="company_id">{t('Enter company')}</Label>
                  <select
                    id="company_id"
                    name="company_id"
                    className="form-select form-select-sm"
                    onChange={(e) => {
                      formik.setFieldValue('company_id', Number(e.target.value))
                    }}
                  >
                    <option
                      key={'selector_hidden_id'}
                      value={undefined}
                      hidden
                    ></option>
                    {companies.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Col>

                <Col md="2">
                  <Label htmlFor="cession_number">{t('cession number')}</Label>
                  <Input
                    name="cession_number"
                    type="text"
                    id="cession_number"
                    className="form-control form-control-sm"
                    placeholder="00/00-0"
                    onChange={formik.handleChange}
                    value={formik.values.cession_number || ''}
                    disabled={false}
                  />
                </Col>
                <Col md="2">
                  <Label htmlFor="cession_price">{t('cession price')}</Label>
                  <InputGroup size="sm">
                    <InputGroupText>%</InputGroupText>
                    <Input
                      name="cession_price"
                      type="number"
                      className="form-control form-control-sm"
                      id="cession_price"
                      onChange={formik.handleChange}
                      value={formik.values.cession_price || ''}
                      disabled={false}
                      invalid={false}
                    />
                  </InputGroup>
                </Col>
                <Col md="2">
                  <Label htmlFor="cession_date">{t('cession date')}</Label>
                  <Flatpickr
                    name="cession_date"
                    id="cession_date"
                    type="date"
                    className="form-control form-control-sm"
                    options={{
                      dateFormat: 'Y-m-d',
                      maxDate: new Date(),
                      onChange: (selectedDates: any, dateStr: any) => {
                        formik.setFieldValue('cession_date', dateStr)
                      },
                    }}
                  />
                </Col>
                <Col md="2">
                  <Label htmlFor="transfer_date">{t('transfer date')}</Label>
                  <Flatpickr
                    name="transfer_date"
                    id="transfer_date"
                    type="date"
                    className="form-control form-control-sm"
                    options={{
                      dateFormat: 'Y-m-d',
                      maxDate: new Date(),
                      onChange: (selectedDates: any, dateStr: any) => {
                        formik.setFieldValue('transfer_date', dateStr)
                      },
                    }}
                  />
                </Col>
              </Row>
              <div className="d-flex gap-5">
                <Button
                  disabled={
                    !formik.values.company_id ||
                    !formik.values.cession_date ||
                    !formik.values.cession_number ||
                    !formik.values.cession_price ||
                    !formik.values.transfer_date ||
                    !file
                  }
                  style={{ marginTop: '15px' }}
                  type="submit"
                >
                  {t('Load portfolio')}
                </Button>
                <Button
                  disabled={selected.length === 0 || !file}
                  style={{ marginTop: '15px' }}
                  type="button"
                  onClick={removeFromSell}
                >
                  {t('Remove portfolio')}
                </Button>
                <Button
                  style={{ marginTop: '15px' }}
                  type="button"
                  onClick={downloadFile}
                >
                  {t('Download the auto sales briefcase')}
                </Button>
              </div>
              <div className="mt-3">
                <Label htmlFor="company_id_zip">{t('Enter company')}</Label>
                <select
                  id="company_id_zip"
                  name="company_id_zip"
                  className="form-select form-select-sm"
                  style={{ width: 'auto', textOverflow: 'ellipsis' }}
                  onChange={(e) => {
                    setSelected(Number(e.target.value))
                  }}
                >
                  <option
                    key={'selector_hidden_id'}
                    value={undefined}
                    hidden
                  ></option>
                  {companies.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              {fieldCompanyError && (
                <Alert color="warning">
                  <strong> {t('No company selected')} </strong>
                </Alert>
              )}
              <Button
                size="md"
                style={{ marginTop: '15px' }}
                type="button"
                onClick={() => {
                  if (selected?.length === 0) {
                    setFieldCompanyError(true)
                  } else {
                    setFieldCompanyError(false)
                    setShowGetZip(!showGetZip)
                  }
                }}
              >
                {t('Download Zip Selled Portfolio')}
              </Button>
            </Form>
            {showGetZip && (
              <div className="d-flex flex-column gap-3 p-3 w-50">
                <Label htmlFor="">{t('Selled Date')}</Label>
                <Flatpickr
                  name="selled_date"
                  id="selled_date"
                  type="date"
                  className="form-control form-control-sm"
                  options={{
                    dateFormat: 'Y-m-d',
                    onChange: (selectedDates: any, dateStr: any) => {
                      setSelectedDate(dateStr)
                    },
                  }}
                />
                <Button
                  disabled={selected.length === 0 || !selectedDate}
                  size="md"
                  style={{ marginTop: '15px' }}
                  type="button"
                  onClick={getLink}
                >
                  {t('Get Link')}
                </Button>
              </div>
            )}

            <div className="table-responsive table-card mt-3">
              <div
                className="btn-group gap-2 p-3"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn btn-primary d-flex align-items-center"
                  disabled={offset === 0}
                  onClick={prev}
                >
                  <i className="ri-arrow-left-line fs-15 me-2" />
                  {`${t('Prev')} ${model.LIST_LIMIT}`}
                </button>
                <button
                  type="button"
                  className="btn btn-primary d-flex align-items-center"
                  disabled={status?.length < model.LIST_LIMIT}
                  onClick={next}
                >
                  {`${t('Next')} ${model.LIST_LIMIT}`}
                  <i className="ri-arrow-right-line fs-15 ms-2" />
                </button>
              </div>
              <OffsetNotice offset={offset} limit={model.LIST_LIMIT} />
              <div className="d-flex gap-3 align-items-center">
                <h4
                  className="page-title-box d-sm-flex align-items-center justify-content-between"
                  style={{ marginTop: '15px' }}
                >
                  {t('Status Sale')}
                </h4>
              </div>
              <List
                style={{ width: 'fit-content' }}
                list={status.map((item: any) => ({
                  ...item,
                }))}
                fieldsDescription={fieldsDescription}
                className="table-sm align-middle table-nowrap mb-0"
                hover
                actions={undefined}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
