import { useTranslation } from 'react-i18next'

import List from './List'

const fieldsDescription = {
  created_at: { isDateTime: true },
  text: {},
}

const EntityContent = ({ entity, style }) => {
  const { t } = useTranslation()
  const list = entity || []

  if (!list.length)
    return (
      <div style={style}>
        <dl className="row mb-0">
          <small>{t('No_notifications')}</small>
        </dl>
      </div>
    )

  return (
    <List list={entity} fieldsDescription={fieldsDescription} style={style} />
  )
}

export default EntityContent
