import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import LimitNotice from 'src/Components/Common/LimitNotice'
import ErrorPage from '../ErrorPage'

import model from 'src/model'

import { getProfiles } from 'src/store/actions'

const debounceProfilesTimers = {}
const DEBOUNCE_TIMEOUT = 1000

const headers = {
  id: { label: '' },
  email: { label: 'email', style: { width: '1%' } },
  email_status: { label: 'email status' },
  phone: { label: 'mobile', style: { width: '1%' } },
  phone_status: { label: 'phone_status' },
  profile_status: { label: 'profile status' },
  //updated_at: {label: "last modified"},
  client: { label: 'client' },
}

export const Profiles = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [strFilter, setStrFilter] = useState('')
  const [fieldsFilter, setFieldsFilter] = useState({})

  const { list, listError } = useSelector((state) => ({
    list: state.profiles.list,
    listLoading: state.profiles.listLoading,
    listError: state.profiles.listError,
  }))

  useEffect(() => {
    dispatch(getProfiles())
  }, [dispatch])

  useEffect(() => {
    if (!strFilter) return

    clearTimeout(debounceProfilesTimers[`filtered-profiles-${strFilter}`])
    debounceProfilesTimers[`filtered-profiles-${strFilter}`] = setTimeout(
      () => {
        console.log(`request profiles with ${strFilter}`)
        dispatch(getProfiles(null, strFilter))
      },
      DEBOUNCE_TIMEOUT,
    )

    return () => {
      if (debounceProfilesTimers[`filtered-profiles-${strFilter}`]) {
        clearTimeout(debounceProfilesTimers[`filtered-profiles-${strFilter}`])
      }
    }
  }, [strFilter])

  useEffect(() => {
    if (Object.keys(fieldsFilter).length === 0) return

    const fieldsFilterJson = JSON.stringify(fieldsFilter)
    clearTimeout(
      debounceProfilesTimers[`filtered-entities-${fieldsFilterJson}`],
    )
    debounceProfilesTimers[`filtered-entities-${fieldsFilterJson}`] =
      setTimeout(() => {
        console.log(`request list with ${fieldsFilterJson}`)
        if (fieldsFilter.gender) {
          fieldsFilter.gender = model.getGenderCode(fieldsFilter.gender)
        }
        dispatch(getProfiles(null, fieldsFilter))
      }, DEBOUNCE_TIMEOUT)

    return () => {
      if (debounceProfilesTimers[`filtered-entities-${fieldsFilterJson}`]) {
        clearTimeout(
          debounceProfilesTimers[`filtered-entities-${fieldsFilterJson}`],
        )
      }
    }
  }, [fieldsFilter])

  console.log('profiles =>', list)

  const title = t('Profiles')
  document.title = title
  const bread = <BreadCrumb title={title} />

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  const minimizeTextStyle = {
    textOverflow: 'ellipsis',
    maxWidth: 200,
    overflow: 'hidden',
  }

  /*if (listLoading) {
    return <LoadingPage title={title} />;
  }*/

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            <div className="live-preview">
              <div className="table-responsive table-card">
                <div className="gridjs-head">
                  <div className="gridjs-search">
                    <input
                      type="search"
                      className="gridjs-input gridjs-search-input"
                      id="iconInput"
                      placeholder="Search..."
                      onChange={(event) => {
                        const filter = event.target.value
                        setStrFilter(filter)
                      }}
                    />
                  </div>
                </div>

                <FieldsFilter
                  fields={{
                    client_id: {},
                    profile_id: {},
                    phone: {},
                    email: {},
                    group_delimiter: {},
                    first_name: {},
                    last_name: {},
                    middle_name: {},
                    birth_date: { date: true },
                    gender: { options: ['male', 'female'] },
                  }}
                  applyFilter={(values) => setFieldsFilter(values)}
                  resetFilter={() => setFieldsFilter({})}
                />

                <LimitNotice limit={model.LIST_LIMIT} />

                <Table
                  hover
                  className="table-sm align-middle table-nowrap mb-0"
                  style={{ width: 'max-content' }}
                >
                  <thead>
                    <tr>
                      {Object.keys(headers).map((header) => (
                        <th className="ps-3" key={header} scope="col">
                          {t(headers[header].label)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.length > 0 &&
                      list.map((entity) => {
                        return (
                          <tr key={entity.id}>
                            {Object.keys(headers).map((fieldName) => {
                              const value = entity[fieldName]
                              const key = `${fieldName}`
                              if (fieldName === 'id')
                                return (
                                  <th
                                    scope="row"
                                    key={key}
                                    style={{ width: '1%' }}
                                  >
                                    <Link
                                      to={`/profile-view?id=${value}`}
                                      className="fw-medium"
                                    >
                                      <i className="ri-eye-line fs-15" />
                                    </Link>
                                  </th>
                                )
                              if (fieldName === 'client')
                                return (
                                  <th scope="row" key={key}>
                                    <Link
                                      to={`/client-view?id=${value.id}`}
                                      className="fw-medium"
                                    >
                                      <i className="ri-user-3-line fs-15" />
                                    </Link>
                                  </th>
                                )
                              return (
                                <td
                                  className="ps-3"
                                  style={{
                                    ...headers[fieldName].style,
                                    ...minimizeTextStyle,
                                  }}
                                  key={key}
                                >
                                  {value}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
