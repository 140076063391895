import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Client from '../../../client'
import Loader from '../Loader'
import { Alert, Button, Input } from 'reactstrap'

const EntityContent = ({ entityId }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [value, setValue] = useState(0)
  const [success, setSuccess] = useState(false)

  const recPaymentSend = () => {
    const reqBody = {
      debt_ratio: value,
    }
    Client.recPayment(entityId, reqBody)
      .then((res) => res === 'ok' && setSuccess(true))
      .catch((err) => setError(err))
  }

  return (
    <div>
      {error && <div className="mt-2 text-danger">{error.toString()}</div>}
      {isLoading && <Loader />}

      {!isLoading && (
        <div className="d-flex flex-column gap-2">
          {t('EnterRecPay')}
          <Input
            style={{ width: '300px' }}
            className="form-control form-control-sm"
            onChange={(e) => setValue(e.target.value)}
          />
          <Button
            style={{ width: '300px' }}
            type="button"
            color="primary"
            onClick={recPaymentSend}
            disabled={value < 0.01 || value > 1}
          >
            {t('WriteOff')}
          </Button>
          {success && <Alert>{t('Done')}</Alert>}
          {error && <Alert color="danger">{error}</Alert>}
        </div>
      )}
    </div>
  )
}

export default EntityContent
