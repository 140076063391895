import { useTranslation } from 'react-i18next'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'

import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import cx from 'classnames'
import {
  selectChatsWithMessagePreview,
  useGetMyChatsQuery,
} from 'src/services/chats'
import { useSelector } from 'src/store'
import moment from 'moment'
import { utcToLocal } from 'src/utils'

export const Chats = () => {
  const { t } = useTranslation()
  const { error, isError } = useGetMyChatsQuery()

  const chats = useSelector(selectChatsWithMessagePreview)

  const title = t('Chats')

  if (isError) {
    return <ErrorPage title={title} error={error} />
  }

  if (!chats) {
    return <LoadingPage title={title} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} />
      <ListGroup>
        {chats.map((chat) => (
          <ListGroupItem key={chat.id} action>
            <Link to={`/chats/${chat.id}`} className="fw-medium">
              <div className="d-flex justify-content-between align-items-center">
                <div style={{ maxWidth: '80%' }}>
                  <h5>{chat.client_name}</h5>
                  <p className="d-flex mb-0 gap-1">
                    {chat.messagePreview.author_type === 'user' && (
                      <b>{t('You')}:</b>
                    )}

                    <span className="text-truncate">
                      {chat.messagePreview.content_type === 'text'
                        ? chat.messagePreview.content
                        : chat.messagePreview.content_type === 'image'
                        ? t('Image')
                        : ''}
                    </span>
                  </p>
                </div>
                <div className="d-flex flex-column flex-shrink-0">
                  <div className="d-flex gap-1 justify-content-center align-items-center">
                    <i
                      className={cx('bx-xs', {
                        // required <i className="bx bx-..."
                        bx: chat.messagePreview.author_type === 'user',
                        // read
                        'bx-check-double':
                          chat.messagePreview.author_type === 'user' &&
                          !chat.messagePreview.is_new,
                        // unread
                        'bx-check':
                          chat.messagePreview.author_type === 'user' &&
                          chat.messagePreview.is_new,
                      })}
                    />
                    <span>
                      {moment(utcToLocal(chat.messagePreview.created_at))
                        // TODO: set moment's local in utils.tsx or here with localStorage.get('I18N_LANGUAGE')
                        .locale('ru')
                        .fromNow()}
                    </span>
                  </div>
                  <div className="align-self-end">
                    <span
                      className={cx('badge rounded-pill bg-primary', {
                        'opacity-0': chat.messagePreview.unreadCount === 0,
                      })}
                    >
                      {chat.messagePreview.unreadCount}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  )
}
